import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import MuiTreeItem from "@material-ui/lab/TreeItem";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { connect, useDispatch } from "react-redux";
import { getListeClient } from "../actions";
import { getClient } from "../selectors/clients";
import { Input } from "@material-ui/core";
import { arrayToTree } from 'performant-array-to-tree'

const useStyles = makeStyles({

    root: {
        overflow: 'auto',
        maxHeight: 180,
        maxWidth: 250,
    },
    label: {
        fontSize: 14
    }
});
let sep = ','
let sachet = ','
let toExpand = []
const TreeItem = withStyles({
    root: {
        "&.Mui-selected > .MuiTreeItem-content": {
            color: "red"
        }
    },
    label: {
        fontSize: 14
    }
})(MuiTreeItem);

const TreeClient = ({ clients, handleChange, multiSelection = true, defautLabel = "Client" }) => {

    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    const [searchClient, setsearchClient] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getListeClient())
    }, [dispatch]);
    useEffect(() => {
        const filteredClient = (items, term, start = false) => {
            if (start) toExpand = []
            return items.reduce((acc, item) => {
                if (contains(item.data.label, term)) {
                    if (term !== '') toExpand.push(item.data.id)
                    acc.push(item);
                } else if (item.children && item.children.length > 0) {

                    let newItems = filteredClient(item.children, term);

                    if (newItems && newItems.length > 0) {
                        let itemToPush = { data: item.data, children: newItems }
                        if (term !== '') toExpand.push(item.data.id)
                        acc.push(itemToPush);
                    }
                }
                return acc;
            }, []);
        }
        if (clients.length) {

            setData(filteredClient(clients, searchClient))
            //console.log(data);
        }

    }, [clients, searchClient]);
    const handleToggle = (event, nodeIds) => {
        //console.log(nodeIds);
        toExpand = nodeIds;
    };
    const inArraySel = (id, data) => {
        let find = false
        Object.values(data).forEach(r => {
            //console.log(r);
            if (r === id) find = true
        })
        return find
    }
    const getIdSelected = (client, nodeIds, trouve = 0) => {
        Object.values(client).forEach(r => {
            if (trouve === 1 || inArraySel(r.data.id, nodeIds)) {
                sachet += sep + r.data.id
                sep = ','
                if (r.children.length && multiSelection) getIdSelected(r.children, nodeIds, 1)
            }
            if (r.children.length) {
                getIdSelected(r.children, nodeIds, 0)
            }
        })
    }
    const handleSelect = (event, nodeIds) => {
        //console.log(data);
        setSelected(nodeIds)
        if (multiSelection) {
            sachet = ''
            sep = ''
            getIdSelected(data, nodeIds, 0)
            handleChange(sachet)
        }
        else handleChange(nodeIds)


    };
    const labelClick = e => {
        e.preventDefault()
    }
    const createClientTree = (clients) => {
        //.filter(r => filter === '' || r.data.label.indexOf(filter) > -1)

        return Object.values(clients).map(c => (
            <TreeItem classes={{ label: classes.label }} onLabelClick={labelClick} key={c.data.id} nodeId={c.data.id} label={c.data.label}>
                {createChildren(c.children)}
            </TreeItem>
        ))
    }
    const createChildren = children => {
        if (children) {
            return Object.values(children).map(c => (
                <TreeItem classes={{ label: classes.label }} onLabelClick={labelClick} key={c.data.id} nodeId={c.data.id} label={c.data.label} >
                    {createChildren(c.children)}
                </TreeItem>
            ))
        }
        else return null
    }
    const contains = (text, term) => {
        if (!text)return false;
        return text.toLowerCase().indexOf(term.toLowerCase()) > -1;
    }
    const handleSearch = e => {
        if (e.target.value === '') toExpand = []
        setsearchClient(e.target.value)
    }
    const reset = () => {
        setSelected([])
        toExpand = []
        setsearchClient('')
        handleChange(null)
    }
    return (
        <div className='form-group' style={{ minHeight: 200, maxHeight: 200 }}>
            <div className="row form-group" >
                <Input value={searchClient} onChange={e => handleSearch(e)} placeholder={defautLabel} />

                <button style={{ fontSize: 17 }} disabled={searchClient === '' && !selected.length} className="btn btn-link btn-sm" onClick={() => reset()}>X</button>
            </div>
            <div >
                <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    multiSelect={multiSelection}
                    expanded={toExpand}
                    selected={selected}
                    onNodeToggle={handleToggle}
                    onNodeSelect={handleSelect}
                >
                    {clients.length !== 0 && createClientTree(data)}

                </TreeView>
            </div>

        </div>

    );
}
const mapStateToProps = (state) => {
    return {

        clients: arrayToTree(getClient(state), { parentId: 'Id_parent' }),
        flatClient: getClient(state)
    }
}
const mapDispatchToProps = {
    getListeClient
}
export default connect(mapStateToProps, mapDispatchToProps)(TreeClient)