import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import TableSuiviMetal from './TableSuiviMetal';
import ReceptionMetalForm from '../atelier/form/ReceptionMetalForm';
import { dateToFrench, getVendrediSemaineEnCours } from '../../helpers/functions';
import { clientOnlyChild } from '../../selectors/clients';
import { getListeClient } from '../../actions';
import { apiATC } from '../../config';
import Axios from 'axios';
import TableSuiviMetalFacture from './TableSuiviMetalFacture';

const SuiviMetal = ({ listeClientInput, getListeClient }) => {
    const currentYear = new Date().getFullYear();
    const firstOfJanuary = `${currentYear}-01-01`;

    const [factureData, setFactureData] = useState([]);
    const [receptionData, setReceptionData] = useState([]);
    const [filteredFactureData, setFilteredFactureData] = useState([]);
    const [filteredReceptionData, setFilteredReceptionData] = useState([]);
    const [client, setClient] = useState(null);
    const [dateDebut, setDateDebut] = useState(firstOfJanuary);
    const [dateFin, setDateFin] = useState(getVendrediSemaineEnCours());
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        getListeClient();
    }, [getListeClient]);

    useEffect(() => {
        Axios.get(`${apiATC}/metal/metal.php?action=suivi`)
            .then(response => {
                const data = response.data;
                if (data && Array.isArray(data.facture) && Array.isArray(data.reception)) {
                    setFactureData(data.facture);
                    setReceptionData(data.reception);
                } else {
                    console.error('La réponse de l\'API n\'est pas dans le format attendu :', data);
                }
            })
            .catch(error => console.error('Erreur lors de la récupération des données :', error));
    }, []);

    useEffect(() => {
        const filterAndCalculateBalance = () => {
            let filteredFacture = [];
            let filteredReception = [];
            let receptionPoidsSum = 0;
            let facturePoidsSum = 0;

            factureData.forEach(item => {
                const itemDate = new Date(item.date);
                const isClientMatch = !client || item.client === client.label;
                const isDateMatch = itemDate >= new Date(dateDebut) && itemDate <= new Date(dateFin);
                if (isClientMatch && isDateMatch) {
                    filteredFacture.push(item);
                    facturePoidsSum += parseFloat(item.poids);
                }
            });

            receptionData.forEach(item => {
                const itemDate = new Date(item.date);
                const isClientMatch = !client || item.client === client.label;
                const isDateMatch = itemDate >= new Date(dateDebut) && itemDate <= new Date(dateFin);
                if (isClientMatch && isDateMatch) {
                    filteredReception.push(item);
                    receptionPoidsSum += parseFloat(item.poids);
                }
            });

            const calculatedBalance = (receptionPoidsSum * 0.92 / 0.753) - facturePoidsSum;
            setFilteredFactureData(filteredFacture);
            setFilteredReceptionData(filteredReception);
            setBalance(calculatedBalance.toFixed(2));
        };

        filterAndCalculateBalance();
    }, [factureData, receptionData, client, dateDebut, dateFin]);

    const handleDateDebutChange = (event) => {
        const value = event.target.value;
        setDateDebut(value);
    };

    const handleDateFinChange = (event) => {
        const value = event.target.value;
        setDateFin(value);
    };

    const ajouter = async (form) => {
        const { client, poids, date } = form;
        const data = {
            table: 'reception_metal',
            id_client: client.id,
            poids: poids,
            date: date
        }
        try {
            await Axios.post(`${apiATC}/general.php?action=ajouter`, data)
                .then(response => {
                    const nouvelId = response.data.id;
                    // console.log(response.data);
                    const nouvelleReception = { id: nouvelId, id_client: client.id, poids: poids, date: date, df: dateToFrench(date, '/'), client: client.label };
                    setReceptionData([...receptionData, nouvelleReception]);
                })

        } catch (error) {
            console.error('Erreur lors de l\'ajout : ', error);
            // Ajoutez ici la logique pour gérer les erreurs
        }
    };

    const supprimer = async (form) => {
        const { id } = form;
        const data = {
            table: 'reception_metal',
            primaryKey: 'id',
            id
        }
        try {
            const response = await Axios.post(`${apiATC}/general.php?action=supprimer`, data);
            // Vérifiez si la suppression s'est bien déroulée
            if (response.status === 200) {
                // Filtrer l'élément à supprimer de l'array receptionData
                const nouvelleReceptionData = receptionData.filter(item => item.id !== id);
                // Mettre à jour receptionData avec le nouvel array filtré
                setReceptionData(nouvelleReceptionData);
            } else {
                console.error('Erreur lors de la suppression.');
                // Ajoutez ici la logique pour gérer les erreurs de suppression
            }
        } catch (error) {
            console.error('Erreur lors de la suppression : ', error);
            // Ajoutez ici la logique pour gérer les erreurs
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div className="col-md-12">
                <h1 style={{ textAlign: 'center' }}>Suivi Métal</h1>
                <h5 style={{ textAlign: 'center' }}>Solde {`${balance} Gr`}</h5>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={2}>
                        <Autocomplete
                            value={client}
                            options={listeClientInput}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, values) => setClient(values)}
                            getOptionSelected={(option) => option.id === client?.id}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField margin="dense" {...params} required label="Client" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id="date-debut"
                            label="Date Début"
                            type="date"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={dateDebut}
                            onChange={handleDateDebutChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id="date-fin"
                            label="Date Fin"
                            type="date"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            value={dateFin}
                            onChange={handleDateFinChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <TableSuiviMetalFacture
                            data={filteredFactureData}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TableSuiviMetal
                            data={filteredReceptionData}
                            supprimer={supprimer}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="h6" align="center">Reception Or</Typography>
                        <ReceptionMetalForm onSubmitForm={ajouter} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        listeClientInput: clientOnlyChild(state),
    };
};

const mapDispatchToProps = {
    getListeClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuiviMetal);
