import { TextField, Button, Grid, Card, CardContent, Typography, Box } from '@material-ui/core';
import Axios from 'axios';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { apiATC, meekroDB } from '../config';
import { getTodaySqlFormat } from '../helpers/functions';
import { getListeOf } from '../actions';

const OfTools = ({ getListeOf }) => {
    const [debut, setDebut] = useState('');
    const [ofDebut, setOfDebut] = useState('');
    const [ofFin, setOfFin] = useState('');
    const [fin, setFin] = useState('');
    const [listeToPrint, setListeToPrint] = useState('');
    const [ofReintegrer, setOfReintegrer] = useState('');
    const [ofToTransfert, setOfToTransfert] = useState('');
    const [ofDestination, setOfDestination] = useState('');
    const [ofStock, setOfStock] = useState('');
    const [ofStockDestination, setOfStockDestination] = useState('');

    const generateListToPrint = (start, end) => {
        const startNum = parseInt(start, 10);
        const endNum = parseInt(end, 10);
        const list = [];

        for (let i = startNum; i <= endNum; i++) {
            list.push(i);
        }

        return list.join(',');
    };

    const printInterval = () => {
        let dateFin = fin === '' ? '99999999' : fin;
        if (debut !== '') window.open(`https://www.extensia-france.com/printers/ofPrinter.php?of_min=${debut}&of_max=${dateFin}`, "_blank");
        setDebut('');
        setFin('');
    }

    const printListeOfs = () => {
        if (listeToPrint !== '') {
            window.open(`https://www.extensia-france.com/printers/ofPrinter.php?ofs=${listeToPrint}`, "_blank");
        }
        setListeToPrint('');
    }

    const printCommandeOf = () => {
        if (ofDebut !== '' && ofFin !== '') {
            const liste = generateListToPrint(ofDebut, ofFin);
            window.open(`https://www.extensia-france.com/printers/cmdOfPrinter.php?ofs=${liste}`, "_blank");
        }
        setOfDebut('');
        setOfFin('');
    }

    const echangerDeuxOfs = () => {
        if (ofToTransfert !== '' && ofDestination !== '') {
            const data = {
                of_source: ofToTransfert,
                of_cible: ofDestination,
            };
            Axios.put(`${meekroDB}/of/of_tools.php`, data)
                .then(() => {
                    setOfToTransfert('');
                    setOfDestination('');
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const reintegrer = () => {
        if (ofReintegrer !== '') {
            Axios.post(`${apiATC}/produit/operation_stock.php?action=reintegrer&ofs=${ofReintegrer}`)
                .then((response) => {
                    console.log(response);
                    
                    alert('OF réintégré n° ' + response.data);
                    setOfReintegrer('');
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const echangerOfAvecStock = () => {
        if (ofStock !== '' && ofStockDestination !== '') {
            const data = {
                of_source: ofStock,
                of_cible: ofStockDestination,
            };
            Axios.post(`${meekroDB}/of/of_tools.php`, data)
                .then((response) => {
                    setOfStock('');
                    setOfStockDestination('');
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const createOfStock = () => {
        const data = {
            table: 'of',
            date_crea: getTodaySqlFormat(),
            date_livr: getTodaySqlFormat(),
            idp: 619,
            id_bijouterie: 249,
            statut: 50,
            type_metal: 1001,
            id_stade: 2,
            typ_transp: 1,
        };
        Axios.post(`${meekroDB}/general.php`, data)
            .then((response) => {
                getListeOf();
                alert('Of créé n° ' + response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            {[
                { title: 'Créer OF', subtitle: 'Stock / Collection', buttonText: 'Créer Of', onClick: createOfStock },
                { title: 'Imprimer Liste', subtitle: 'Of ou liste Ofs séparés par \',\'', buttonText: 'Imprimer', onClick: printListeOfs, input: { value: listeToPrint, onChange: e => setListeToPrint(e.target.value), placeholder: "Liste Ofs avec ','" } },
                { title: 'Imprimer Intervalle', subtitle: 'Of Début et Fin', buttonText: 'Imprimer', onClick: printInterval, inputs: [{ label: 'Of Début', value: debut, onChange: e => setDebut(e.target.value) }, { label: 'Of Fin', value: fin, onChange: e => setFin(e.target.value) }] },
                { title: 'Imprimer Commande OF', subtitle: 'Ofs avec Photo', buttonText: 'Imprimer', onClick: printCommandeOf, inputs: [{ label: 'Of Début', value: ofDebut, onChange: e => setOfDebut(e.target.value) }, { label: 'Of Fin', value: ofFin, onChange: e => setOfFin(e.target.value) }] },
                { title: 'Echanger des Ofs', subtitle: 'Of à transferer et Déstination', buttonText: 'Echanger', onClick: echangerDeuxOfs, inputs: [{ label: 'Of à transferer', value: ofToTransfert, onChange: e => setOfToTransfert(e.target.value) }, { label: 'Of déstination', value: ofDestination, onChange: e => setOfDestination(e.target.value) }] },
                { title: 'Transfert OF Stock', subtitle: 'Transfert Stock vers Client', buttonText: 'Echanger', onClick: echangerOfAvecStock, inputs: [{ label: 'Of Stock', value: ofStock, onChange: e => setOfStock(e.target.value) }, { label: 'Of Client', value: ofStockDestination, onChange: e => setOfStockDestination(e.target.value) }] },
                { title: 'Réintegrer OF Livré', buttonText: 'Réintegrer', onClick: reintegrer, input: { value: ofReintegrer, onChange: e => setOfReintegrer(e.target.value), placeholder: "N° OF" } }
            ].map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                        <CardContent>
                            <Typography variant="h5" style={{ margin: '10px auto' }}>
                                {item.title}
                            </Typography>
                            {item.subtitle && (
                                <Typography variant="h6" style={{ margin: '10px auto' }}>
                                    {item.subtitle}
                                </Typography>
                            )}
                        </CardContent>
                        <CardContent>
                            {item.input && (
                                <TextField
                                    fullWidth
                                    {...item.input}
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            {item.inputs && item.inputs.map((input, idx) => (
                                <Box mt={2} key={idx}>
                                    <TextField
                                        fullWidth
                                        label={input.label}
                                        type='number'
                                        value={input.value}
                                        onChange={input.onChange}
                                        variant='outlined'
                                        size='small'
                                    />
                                </Box>
                            ))}
                        </CardContent>
                        <CardContent>
                            <Button variant="contained" color="primary" size='small' onClick={item.onClick}>
                                {item.buttonText}
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

const mapDispatchToProps = {
    getListeOf
};

export default connect(null, mapDispatchToProps)(OfTools);
