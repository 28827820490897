import React, { useEffect, useState } from 'react'
import {
    getListeProduit, getTypeProduit, getListePierre, getTypeTarif, modifTarifProd
} from '../../actions'
import { connect } from 'react-redux'
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import { apiATC, localization, meekroDB } from '../../config';
import { List } from 'immutable';
import Axios from 'axios';
import { getPierre } from '../../selectors/pierre';

const Centre = ({ getListeProduit, listeRef, listeProduit, getTypeProduit, listePierre, getListePierre, listeTypeTarif, getTypeTarif, modifTarifProd }) => {
    useEffect(() => getListeProduit(), [getListeProduit]);
    useEffect(() => getTypeProduit(), [getTypeProduit]);
    useEffect(() => getTypeTarif(), [getTypeTarif]);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getListePierre();
            // ...
        }
        fetchData();
    }, [getListePierre]); // Or [] if effect doesn't need props or state




    const [id, setId] = useState('')
    const [ref, setRef] = useState(null)
    const [rows, setRow] = useState([])
    // const [openImage, setOpenImage] = useState(false)
    const [image, setImage] = useState("no_pic.jpg")

    const [description, setDescription] = useState('')
    const [facon, setFacon] = useState('')
    const [fsf, setFsf] = useState('')
    const [poids, setPoids] = useState('')
    const [serti, setSerti] = useState('')
    const [divers, setDivers] = useState('')
    const [typeTarif, setTypeTarif] = useState(null)


    const [pierre, setPierre] = useState(null)
    const [qte, setQte] = useState('')
    const [poidsPierre, setPoidsPierre] = useState('')
    const [centre, setCentre] = useState(false)

    const [pierreExist, setPierreExist] = useState(false)



    const returnList = () => {
        const data = List(listeProduit)
        return data.toJS()
    }
    const handleClick = (event, rowData) => {
        const refSelected = Object.values(listeRef).find(r => r.id_cv === rowData.id_cv)
        const typeTarif = Object.values(listeTypeTarif).find(r => r.id === rowData.id_tarif_produit)
        setId(rowData.id_centre)
        setFacon(rowData.plus_mo)
        setFsf(rowData.plus_fonte)
        setPoids(rowData.plus_poids)
        setSerti(rowData.plus_serti)
        setDivers(rowData.prix_divers)
        setDescription(rowData.description)
        setRef(refSelected)
        setTypeTarif(typeTarif)
        setImage(rowData.image)
        Axios.get(`${apiATC}/pierre/centre_pierre.php?id=${rowData.id_centre}`)
            .then((response) => {
                setRow(response.data)
                resetPierre()
                setPierreExist(false)
            })
            .catch(error => {
                console.log(error);
            });

    }
    const handleSave = () => {
        const data = {
            table: 'centre',
            primaryKey: 'id_centre',
            id,
            id_cv: ref.id_cv,
            ref: description,
            plus_mo: facon,
            plus_fonte: fsf,
            plus_poids: poids,
            plus_serti: serti,
            prix_divers: divers,
            img_centre: image,
        }
        Axios.post(`${apiATC}/general.php?action=modifier`, data)
            .then((response) => {
                const produit = {
                    table: 'produit',
                    primaryKey: 'id_centre',
                    id,
                    id_tarif_produit: typeTarif.id,
                }
                Axios.post(`${apiATC}/general.php?action=modifier`, produit)
                    .then((response) => {
                        modifTarifProd(id)
                    })
                    .catch(error => {
                        console.log(error);
                    });
                getListeProduit()
            })
            .catch(error => {
                console.log(error);
            });

    }
    const handleCreate = () => {
        if (!ref || description === '') alert('Veuillez renseigner tout les champs nécessaires')
        else {
            const exist = Object.values(listeProduit).find(p => p.ref === ref.ref && p.description === description)
            if (exist !== undefined) alert('Combinaison ref et description déja existante')
            else if (window.confirm('Voulez vous créer un nouveau produit ?')) {
                const data = {
                    table: 'centre',
                    ref: description,
                    plus_mo: facon,
                    plus_fonte: fsf,
                    plus_poids: poids,
                    plus_serti: serti,
                    prix_divers: divers,
                    id_cv: ref.id_cv,

                }
                Axios.post(`${apiATC}/general.php?action=ajouter`, data)
                    .then((response) => {
                        const insertId = response.data
                        const prod = {
                            table: 'produit',
                            id_centre: insertId,
                            id_tarif_produit: typeTarif.id,
                        }
                        Axios.post(`${apiATC}/general.php?action=ajouter`, prod)
                            .then((response) => {
                                // getListeProduit()
                                Object.values(rows).forEach(p => {
                                    const centrePierre = {
                                        table: 'centre_pierres',
                                        id_centre: insertId,
                                        id_pierre: p.id_tarif,
                                        qte: p.qte,
                                        pds_r: p.pds_r,
                                        centre: p.centre
                                    }

                                    Axios.post(`${apiATC}/general.php?action=ajouter`, centrePierre)
                                        .then((response) => {
                                            Axios.get(`${meekroDB}/produit/modif_tarif_produit.php?centres=${insertId}`)
                                                .then(response => {
                                                    modifTarifProd(id)
                                                    getListeProduit().then(() => {
                                                        getListeProduit(returnList)
                                                    })
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                })
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
        }


    }
    const changeRef = (values) => {
        if (!values) {
            resetForm()
            resetPierre()
            setRow([])
        }
        // console.log(values);
        else {
            setRef(values)
            setFacon(values.facon)
            setFsf(values.facon_fonte)
            setPoids(values.poids_metal)
            setSerti(values.serti_min)
        }
    }
    const selectPierre = (data) => {

        const pierreSelect = Object.values(listePierre).find(p => p.id_tarif === data.id_tarif)
        // console.log(pierreSelect);

        setPierre(pierreSelect !== undefined ? pierreSelect : null)
        setQte(data.qte)
        setPoidsPierre(data.pds_r)
        setCentre(data.centre === 1 ? true : false)
        setPierreExist(true)
    }
    const handleCreatePierre = () => {
        if (id === '' || !pierre || qte === '' || poidsPierre === '') alert('Veuillez renseigner tout les champs nécessaires *')
        else {
            const data = {
                id_centre: id,
                id_pierre: pierre.id_tarif,
                qte,
                pds_r: poidsPierre,
                centre: centre ? 1 : 0
            }
            Axios.post(`${apiATC}/pierre/centre_pierre.php?action=inserer`, data)
                .then((response) => {

                    // console.log(id);
                    modifTarifProd(id)
                    refreshDetailPierre()
                })
                .catch(error => {
                    console.log(error);
                });
        }

    }
    const handleSavePierre = () => {
        const data = {
            id_centre: id,
            id_pierre: pierre.id_tarif,
            qte,
            pds_r: poidsPierre,
            centre: centre ? 1 : 0
        }
        Axios.post(`${apiATC}/pierre/centre_pierre.php?action=modifier`, data)
            .then((response) => {
                modifTarifProd(id)
                refreshDetailPierre(false)
            })
            .catch(error => {
                console.log(error);
            });


    }
    const handleDeletePierre = () => {
        const data = {
            id_centre: id,
            id_pierre: pierre.id_tarif,
        }
        Axios.post(`${apiATC}/pierre/centre_pierre.php?action=supprimer`, data)
            .then((response) => {
                modifTarifProd(id)
                refreshDetailPierre()
                setPierreExist(false)
                resetPierre()
            })
            .catch(error => {
                console.log(error);
            });
    }
    const refreshDetailPierre = (reset = true) => {
        Axios.get(`${apiATC}/pierre/centre_pierre.php?id=${id}`)
            .then((response) => {
                setRow(response.data)
                reset && resetPierre()
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleSelectPierre = (values) => {
        const exist = Object.values(rows).find(p => p.id_tarif === values.id_tarif)
        if (exist === undefined) setPierreExist(false)
        else setPierreExist(true)
        setPierre(values)
    }
    const resetForm = () => {
        setId('')
        setRef(null)
        setDescription('')
        setFacon('')
        setFsf('')
        setPoids('')
        setSerti('')
        setDivers('')
        setTypeTarif(null)
    }
    const resetPierre = () => {
        setPierre(null)
        setPoidsPierre('')
        setCentre(false)
        setQte('')
    }



    const useStyles = makeStyles({
        table: {
            //minWidth: 650,
        },
        selected: {
            backgroundColor: 'red'
        }
    });

    const classes = useStyles();
    const getBackgroundColor = (row) => {
        if (pierre) {
            if (row.id_tarif === pierre.id_tarif) return '#AED1FF'
        }
        else return ''

    }
    return (
        <div className="container-fluid" >
            <div className="row">
                <div className="col-sm">
                    <MaterialTable
                        style={{ fontSize: 12, zIndex: 0 }}
                        columns={[
                            { title: 'Type', field: 'typeProd' },
                            { title: 'Ref', field: 'ref' },
                            { title: 'Description', field: 'description' },
                            { title: 'Facon', field: 'plus_mo' },
                            { title: 'FSF', field: 'plus_fonte' },
                            { title: 'Poids', field: 'plus_poids' },
                            { title: 'Serti', field: 'plus_serti' },
                            { title: 'Divers', field: 'prix_divers' },
                            { title: 'Tarif', field: 'descTarif' },
                        ]}
                        title={<div style={{ width: 170, fontSize: 16, display: "flex" }}>Centre</div>}
                        data={returnList()}
                        options={{
                            pageSize: 10,
                            rowStyle: rowData => ({
                                backgroundColor: (id === rowData.id_centre) ? '#AED1FF' : ''
                            })
                        }}
                        onRowClick={handleClick}
                        localization={localization}
                    />
                </div>
                <div className="col-sm">
                    <form >
                        <div className="form-group">
                            <Autocomplete
                                value={ref}
                                options={listeRef}
                                getOptionLabel={(option) => option.ref}
                                onChange={(e, values) => changeRef(values)}
                                getOptionSelected={(option) => option.id_cv === ref.id_cv}
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                fullWidth
                                renderInput={(params) => <TextField required autoFocus  {...params} label="Ref" variant="outlined" />}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Description" variant="outlined"
                                required
                                fullWidth
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Facon" variant="outlined"
                                fullWidth
                                value={facon}
                                onChange={e => setFacon(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Facon sur Fonte" variant="outlined"
                                fullWidth
                                value={fsf}
                                onChange={e => setFsf(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Poids" variant="outlined"
                                fullWidth
                                value={poids}
                                onChange={e => setPoids(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Serti" variant="outlined"
                                fullWidth
                                value={serti}
                                onChange={e => setSerti(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Divers" variant="outlined"
                                fullWidth
                                value={divers}
                                onChange={e => setDivers(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <Autocomplete
                                value={typeTarif}
                                options={listeTypeTarif}
                                getOptionLabel={(option) => option.description}
                                onChange={(e, values) => setTypeTarif(values)}
                                getOptionSelected={(option) => option.id === typeTarif.id}
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                fullWidth
                                renderInput={(params) => <TextField autoFocus  {...params} label="Type Tarif" variant="outlined" />}
                            />
                        </div>
                        {/* <div className="form-group">
                            <button style={{ color: '#007bff' }} onClick={() => setOpenImage(true)} className='btn btn-primary btn-lg' >Image</button>
                            <Dialog
                                open={openImage}
                                onClose={() => setOpenImage(false)}
                                fullWidth={true}
                            >
                                <DialogTitle id="form-dialog-title">Choix Image</DialogTitle>
                                <DialogTitle style={{ position: 'absolute', right: 40 }} onClick={() => setOpenImage(false)}><button className="btn btn-link">Fermer</button></DialogTitle>
                                <DialogContent>
                                    <ChooseImage setImage={setImage} image={image} />
                                </DialogContent>

                            </Dialog>
                        </div> */}



                        <div className="form-group">
                            <button type="button" className="btn btn-link btn-sm" onClick={handleCreate} >Créer</button>
                            <button type="button" className="btn btn-link btn-sm" onClick={handleSave} disabled={id === ''}>Enregistrer</button>
                        </div>

                    </form>
                </div>
                <div className="col-sm">
                    <Table className={classes.table} size="small" aria-label="a dense table" >
                        <TableHead>
                            <TableRow>
                                <TableCell>Qte</TableCell>
                                <TableCell align="right">Type</TableCell>
                                <TableCell align="right">Forme</TableCell>
                                <TableCell align="right">PoidsU</TableCell>
                                <TableCell align="right">Dimension</TableCell>
                                <TableCell align="right">Poids</TableCell>
                                <TableCell align="right">Qualité</TableCell>
                                <TableCell align="right">Prix/Ct</TableCell>
                                {/* <TableCell align="right">Centre</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow hover key={row.id_pierre} onClick={() => selectPierre(row)}>
                                    <TableCell style={{ backgroundColor: getBackgroundColor(row), color: row.centre === 1 ? 'red' : '' }} component="th" scope="row">
                                        {row.qte}
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: getBackgroundColor(row), color: row.centre === 1 ? 'red' : '' }} align="right">{row.type}</TableCell>
                                    <TableCell style={{ backgroundColor: getBackgroundColor(row), color: row.centre === 1 ? 'red' : '' }} align="right">{row.laforme}</TableCell>
                                    <TableCell style={{ backgroundColor: getBackgroundColor(row), color: row.centre === 1 ? 'red' : '' }} align="right">{row.pds_u}</TableCell>
                                    <TableCell style={{ backgroundColor: getBackgroundColor(row), color: row.centre === 1 ? 'red' : '' }} align="right">{row.ladim}</TableCell>
                                    <TableCell style={{ backgroundColor: getBackgroundColor(row), color: row.centre === 1 ? 'red' : '' }} align="right">{row.pds_r}</TableCell>
                                    <TableCell style={{ backgroundColor: getBackgroundColor(row), color: row.centre === 1 ? 'red' : '' }} align="right">{row.laqual}</TableCell>
                                    <TableCell style={{ backgroundColor: getBackgroundColor(row), color: row.centre === 1 ? 'red' : '' }} align="right">{row.prix}</TableCell>
                                    {/* <TableCell style={{ color: row.centre === 1 ? 'red' : '' }} align="right">{row.centre}</TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <div className="form-group" style={{ paddingTop: 20 }}>
                        <Autocomplete
                            value={pierre}
                            options={listePierre}
                            getOptionLabel={(option) => option.descriptionPierre}
                            onChange={(e, values) => handleSelectPierre(values)}
                            getOptionSelected={(option) => option.id_tarif === pierre.id_tarif}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            fullWidth
                            renderInput={(params) => <TextField required autoFocus  {...params} label="Pierre" variant="outlined" size='small' />}
                        />

                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <TextField label="Qte" variant="outlined"
                                    value={qte}
                                    onChange={e => setQte(e.target.value)}
                                    size='small'
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <TextField label="Poids" variant="outlined"
                                    value={poidsPierre}
                                    onChange={e => setPoidsPierre(e.target.value)}
                                    size='small'
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={centre}
                                            onChange={(e) => setCentre(e.target.checked)}
                                            size='small'
                                            color="primary"
                                        />
                                    }
                                    label="Centre"
                                />
                            </div>
                        </div>

                    </div>
                    <div className="form-group">
                        <button type="button" className="btn btn-link btn-sm" onClick={handleCreatePierre} disabled={pierreExist}>Ajouter</button>
                        <button type="button" className="btn btn-link btn-sm" onClick={handleSavePierre} disabled={!pierreExist}>Enregistrer</button>
                        <button type="button" className="btn btn-link btn-sm" onClick={handleDeletePierre} disabled={!pierreExist}>Supprimer</button>
                    </div>

                </div>

            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        listeRef: state.produit.listeReference,
        listeTypeProd: state.generalData.listeTypeProduit,
        listeProduit: state.produit.listeProduit,
        listePierre: getPierre(state),
        listeTypeTarif: state.generalData.listeTypeTarif,

    }
}
const mapDispatchToProps = {
    getListeProduit,
    getTypeProduit,
    getListePierre,
    getTypeTarif,
    modifTarifProd,

}
export default connect(mapStateToProps, mapDispatchToProps)(Centre)