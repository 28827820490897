import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { getListForSqlIn } from '../helpers/functions';

const FilterSelect = ({ options = [], label = 'Label', labelId = 'id_stade', labelDescription = 'description', onChange }) => {
    const [current, setCurrent] = useState([]);
    const [selected, setSeleced] = useState([]);

    const handleChange = (e, values) => {
        if (values) {
            const exist = selected.find(r => r[labelId] === values[labelId]);
            if (exist === undefined) {
                setCurrent(values);
                setSeleced(selected.concat(values));
                onChange(getListForSqlIn(selected.concat(values), labelId), label);
            }
            setCurrent([]);
        }
    };

    const handleRemoveItem = id => {
        setSeleced(selected.filter(item => item[labelId] !== id));
        onChange(getListForSqlIn(selected.filter(item => item[labelId] !== id), labelId), label);
    };

    const reset = () => {
        setSeleced([]);
        onChange(getListForSqlIn([], labelId), label);
        setCurrent('');
    };

    const selectAll = () => {
        setSeleced(options);
        onChange(getListForSqlIn(options, labelId), label);
    };

    return (
        <div className='form-group mx-auto w-100' style={{ textAlign: 'center', maxWidth: 350, minHeight: 200, maxHeight: 250 }}>
            <Autocomplete
                value={current}
                options={options}
                getOptionLabel={(option) => option[labelDescription] || ''}
                onChange={(e, values) => handleChange(e, values)}
                freeSolo
                autoHighlight
                autoComplete
                clearOnEscape
                renderInput={(params) => <TextField {...params} label={label} variant="outlined" margin="dense" />}
            />
            <div className="mx-auto w-100">
                <button className="btn btn-link btn-sm" onClick={selectAll}>All</button>
                {selected.length !== 0 && <button className="btn btn-link btn-sm" onClick={reset}>Clear</button>}
            </div>
            <div className='overflow-auto mx-auto w-100' style={{ maxHeight: 150, overflowY: 'auto' }}>
                {selected.map((item, i) => {
                    return (
                        <div onClick={() => handleRemoveItem(item[labelId])} key={i + item[labelDescription]}>
                            {item[labelDescription]}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FilterSelect;
