import React, { Component } from 'react'
import { getListeProduit, getListeClient, getMetaux, getTypeTransport, getStade, addOf, updateOf, deleteOf, deleteOfs, getListeOf, setOfToEdit, getTypeCom, getTypeCommande } from '../actions'
import { clientOnlyChild } from '../selectors/clients'
import { connect } from 'react-redux'
import SearchProd from '../components/searchProd'
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import MaterialTable from 'material-table'
import { List } from 'immutable'
import { localization, meekroDB } from '../config'
import Axios from 'axios'


class Of extends Component {
    today = new Date()
    idOf = 0
    dataTypeCommission = [{ id: 2, description: 'AUTRE' }, { id: 1, description: 'DIRECT OLIVIER' }, { id: 0, description: 'PRESCRIPTEUR' }]
    //today.setDate(("0" + this.today.getDate()).slice(-2) + 14)
    state = {
        id: 1,
        produit: null,
        refClient: '',
        notes: '',
        descriptionConfie: '',
        poidsConfie: '',
        openListe: true,
        date: this.today.getFullYear() + '-' + ("0" + (this.today.getMonth() + 1)).slice(-2) + '-' + ("0" + this.today.getDate()).slice(-2),
        client: null,
        stade: null,
        commission: { id_typ_com: '1', desc: 'Aucune' },
        typeTransport: null,
        typeMetal: null,
        imperatif: false,
        tournee: false,
        dim: '',
        refSelected: '',
        prixFact: '',
        sp: false,
        facon: false,
        devis: false,
        typeCommande: { id: "1", label: 'COMMANDE' },

    }

    componentDidMount() {
        this.props.getListeProduit()
        this.props.getListeClient()
        this.props.getMetaux()
        this.props.getTypeTransport()
        this.props.getStade()
        this.props.getTypeCom()
        this.props.getTypeCommande()
    }

    handleSelect = (prod, openListe = true) => {
        this.setState({ produit: prod, refSelected: prod.ref + ' ' + prod.description, openListe }, () => this.createPrixFact())
    }
    createPrixFact = () => {
        let prixFact = 0
        if (this.state.produit && this.state.stade) {
            //(this.state.produit.totalcv);
            switch (this.state.stade.id_stade) {
                case '1':
                    prixFact = this.state.produit.mfs
                    break;
                case '3':
                    prixFact = this.state.produit.totalcv
                    break;
                default:
                    prixFact = this.state.produit.total3
                    break;
            }
            this.setState({ prixFact })
        }
    }
    switch = () => {
        this.setState({ openListe: !this.state.openListe })
    }
    verifSiExist = async () => {
        const verif = {
            idp: this.state.produit.id_centre,
            metal: this.state.typeMetal.id_type,
        };

        try {
            const response = await Axios.post(`${meekroDB}/of/verif_exist_stock.php`, verif);

            if (response.data.length > 0) {
                // Construire le message d'alerte
                // console.log(response.data);

                const message = `${response.data[0].ref} ${response.data[0].designation} OF N° ${response.data[0].num_sachet} produit est déjà existant dans le stock, lieu : ${response.data[0].lieu}. Continuer ?`;

                // Afficher l'alerte et demander à l'utilisateur s'il souhaite continuer
                if (window.confirm(message)) {
                    return false;
                } else {
                    // Si l'utilisateur annule, arrêter
                    return true;
                }
            }
            else return false;
        } catch (error) {
            console.log(error);
            return false;
        }
    };
    createDataOf = () => {
        const { state } = this

        const data = {
            id: state.id,
            date: state.date,
            produit: state.produit,
            idp: state.produit.id_centre,
            refSelected: state.produit.ref + ' ' + state.produit.description,
            imperatif: state.imperatif,
            date_crea: this.today.getFullYear() + '-' + ("0" + (this.today.getMonth() + 1)).slice(-2) + '-' + ("0" + this.today.getDate()).slice(-2),
            client: state.client && state.client,
            idClient: state.client && state.client.id,
            descBijouterie: state.client && state.client.label,
            typeTransport: state.typeTransport && state.typeTransport,
            typeCommande: state.typeCommande && state.typeCommande,
            descTypeTransp: state.typeTransport && state.typeTransport.description,
            idTransport: state.typeTransport && state.typeTransport.id_type,
            typeMetal: state.typeMetal && state.typeMetal,
            descTypeMetal: state.typeMetal && state.typeMetal.description,
            idTypeMetal: state.typeMetal && state.typeMetal.id_type,
            stade: state.stade && state.stade,
            commission: state.commission ? state.commission.id_typ_com : '1',
            descStade: state.stade && state.stade.description,
            idStade: state.stade && state.stade.id_stade,
            notes: state.notes,
            refClient: state.refClient,
            descriptionConfie: state.descriptionConfie,
            poidsConfie: state.poidsConfie,
            prixFact: state.prixFact,
            tournee: state.tournee,
            dim: state.dim,
            sp: state.sp,
            facon: state.facon,
            devis: state.devis,
        }
        this.setState({ id: this.state.id + 1 })
        return data
    }
    onSubmit = async (e) => {
        e.preventDefault();

        // Attendre que la vérification de l'existence soit terminée
        if ((await this.verifSiExist())) return;

        this.props.addOf(this.createDataOf());
        this.setState({ id: this.state.id + 1 });
    }
    handleSave = async (e) => {
        e.preventDefault();

        // Attendre que la vérification de l'existence soit terminée
        if ((await this.verifSiExist())) return;

        this.props.updateOf(this.createDataOf());
    }
    handleRemove = (e) => {
        e.preventDefault()
        this.props.deleteOf(this.state.id)
    }
    returnList = () => {
        const dataOfs = List(this.props.ofs)
        return dataOfs.toJS()
    }
    handleClick = (event, rowData) => {
        //this.props.setOfToEdit(rowData)
        const commission = Object.values(this.props.listeTypeCom).find(r => r.id_typ_com === rowData.commission)
        const { date, imperatif, client, refClient, typeTransport, typeCommande, typeMetal, stade, notes, descriptionConfie, poidsConfie, produit, refSelected, dim, tournee, id, prixFact, pierres_clt, devis, facon } = rowData
        this.setState({ date, imperatif, client, refClient, typeTransport, typeCommande, typeMetal, commission, stade, notes, descriptionConfie, poidsConfie, produit, refSelected, dim, id, tournee, prixFact, sp: pierres_clt, devis, facon })
    }
    prenventEnter = e => {
        if (e.key === 'Enter') e.preventDefault()
        //console.log(this.props.ofs);
    }

    handleCreateOfs = e => {
        let tab = []
        let user;
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData && userData.length > 0) {
            user = userData[0].id_user;
        } else {
            user = "99";
        }
        Object.values(this.props.ofs).forEach(of => {
            tab.push({
                idp: of.idp,
                date_livr: of.date,
                date_l_imp: of.imperatif ? 1 : 0,
                date_crea: of.date_crea,
                id_bijouterie: of.idClient,
                statut: 50,
                typ_transp: of.idTransport,
                type_metal: of.idTypeMetal,
                Notes: of.notes,
                ref_clt: of.refClient,
                poids_confie: of.poidsConfie,
                notes_confie: of.descriptionConfie,
                id_stade: of.idStade,
                tournee: of.tournee ? 1 : 0,
                typ_com: of.commission ? of.commission : 1,
                dim: of.dim,
                prix_fact: of.prixFact,
                pierres_clt: of.sp === true ? '1' : '0',
                facon: of.facon === true ? '1' : '0',
                devis: of.devis === true ? '1' : '0',
                id_type_commande: of.typeCommande ? of.typeCommande.id : 1,
                id_user: user,
            })
        })
        const data = {
            records: tab,
            table: 'of',
        }
        //console.log(data);

        Axios.post(`${meekroDB}/general_multi.php`, data)
            .then(response => {
                this.props.deleteOfs()
                this.props.getListeOf()
            })
            .catch(error => {
                console.log(error);
            });


    }
    render() {
        return (

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm" >
                        <MaterialTable
                            style={{ fontSize: 12, zIndex: 0 }}

                            columns={[
                                { title: 'Client', field: 'descBijouterie' },
                                { title: 'Ref', field: 'refSelected' },
                                { title: 'Stade', field: 'descStade' },
                                { title: 'Transport', field: 'descTypeTransp' },
                                { title: 'Métal', field: 'descTypeMetal' },
                                { title: 'Dim', field: 'dim' },
                            ]}
                            options={{
                                rowStyle: rowData => ({
                                    backgroundColor: (this.state.id === rowData.id) ? '#AED1FF' : ''
                                })
                            }}
                            data={this.returnList()}
                            title={this.props.ofs.length !== 0 ? <button onClick={this.handleCreateOfs} className="btn btn-link btn-sm">Enregistrer</button> : ''}

                            onRowClick={this.handleClick}
                            localization={localization}
                        />
                    </div>
                    <div className="col-sm">
                        <form onSubmit={this.onSubmit}  >
                            <div className='row'>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <TextField onKeyPress={this.prenventEnter}
                                            variant="outlined"
                                            value={this.state.date}
                                            id="date"
                                            label="Livraison"
                                            type="date"
                                            fullWidth
                                            onChange={(e) => this.setState({ date: e.target.value })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.imperatif}
                                                    onChange={(e) => this.setState({ imperatif: e.target.checked })}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label="Impératif"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.tournee}
                                                    onChange={(e) => this.setState({ tournee: e.target.checked })}
                                                    color="primary"
                                                />
                                            }
                                            label="Tournée"
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <Autocomplete
                                            value={this.state.commission}
                                            options={this.props.listeTypeCom}
                                            getOptionLabel={(option) => option.desc}
                                            onChange={(event, values) => this.setState({ commission: values })}
                                            getOptionSelected={(option) => option.id_typ_com === this.state.commission.id_typ_com}

                                            autoHighlight
                                            autoComplete
                                            clearOnEscape

                                            renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} label="Commission" variant="outlined" />}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.facon}
                                                    onChange={(e) => this.setState({ facon: e.target.checked })}
                                                    color="primary"
                                                />
                                            }
                                            label="Façon"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.sp}
                                                    onChange={(e) => this.setState({ sp: e.target.checked })}
                                                    color="primary"
                                                />
                                            }
                                            label="Spécial"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.devis}
                                                    onChange={(e) => this.setState({ devis: e.target.checked })}
                                                    color="primary"
                                                />
                                            }
                                            label="Devis"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <Autocomplete
                                        value={this.state.client}
                                        options={this.props.listeClientInput}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, values) => this.setState({ client: values })}
                                        getOptionSelected={(option) => option.id === this.state.client.id}
                                        autoHighlight
                                        autoComplete
                                        clearOnEscape
                                        renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Client" variant="outlined" margin="normal" />}
                                    />
                                </div>
                                <div className="col-sm">
                                    <Autocomplete
                                        value={this.state.typeCommande}
                                        options={this.props.listetypeCommande}
                                        getOptionLabel={(option) => option.label}
                                        onChange={(event, values) => this.setState({ typeCommande: values })}
                                        getOptionSelected={(option) => option.id === this.state.typeCommande.id}
                                        autoHighlight
                                        autoComplete
                                        clearOnEscape
                                        renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Type Commande" variant="outlined" margin="normal" />}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <TextField onKeyPress={this.prenventEnter} fullWidth label="Réf Clt" variant="outlined"
                                            value={this.state.refClient}
                                            onChange={(e) => this.setState({ refClient: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <Autocomplete
                                            value={this.state.typeTransport}
                                            options={this.props.listeTypeTransport}
                                            getOptionLabel={(option) => option.description}
                                            onChange={(event, values) => this.setState({ typeTransport: values })}
                                            getOptionSelected={(option) => option.id_type === this.state.typeTransport.id_type}
                                            autoHighlight
                                            autoComplete
                                            clearOnEscape
                                            renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Type Transport" variant="outlined" />}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row'>

                                <div className="col-sm">
                                    <div className="form-group">
                                        <Autocomplete
                                            value={this.state.typeMetal}
                                            options={this.props.listeMetaux}
                                            getOptionLabel={(option) => option.description}
                                            onChange={(event, values) => this.setState({ typeMetal: values })}
                                            getOptionSelected={(option) => option.id_type === this.state.typeMetal.id_type}

                                            autoHighlight
                                            autoComplete
                                            clearOnEscape
                                            renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Type Métal" variant="outlined" margin="normal" />}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <Autocomplete
                                            value={this.state.stade}
                                            options={this.props.listeStade}
                                            getOptionLabel={(option) => option.description}
                                            onChange={(event, values) => this.setState({ stade: values }, () => this.createPrixFact())}
                                            getOptionSelected={(option) => option.id_stade === this.state.stade.id_stade}

                                            autoHighlight
                                            autoComplete
                                            clearOnEscape

                                            renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Stade" variant="outlined" margin="normal" />}
                                        />
                                    </div>
                                </div>

                            </div>


                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <TextField onKeyPress={this.prenventEnter} fullWidth label="Notes" variant="outlined" multiline={true} rows={3}
                                            value={this.state.notes}
                                            onChange={(e) => this.setState({ notes: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <TextField onKeyPress={this.prenventEnter} fullWidth label="Desc Confié" variant="outlined" multiline={true} rows={3}
                                            value={this.state.descriptionConfie}
                                            onChange={(e) => this.setState({ descriptionConfie: e.target.value })}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <TextField onKeyPress={this.prenventEnter} fullWidth label="Dim / Longueur" variant="outlined"
                                            value={this.state.dim}
                                            onChange={(e) => this.setState({ dim: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <TextField onKeyPress={this.prenventEnter} fullWidth label="Poids Confié" variant="outlined"
                                            value={this.state.poidsConfie}
                                            onChange={(e) => this.setState({ poidsConfie: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <TextField onKeyPress={this.prenventEnter} fullWidth label="Prix Fact" variant="outlined"
                                            value={this.state.prixFact}
                                            onChange={(e) => this.setState({ prixFact: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>




                            <div className="form-group">
                                <SearchProd onEnter={this.switch} produit={this.state.produit} handleSelect={this.handleSelect} autoFocus={false} viewList={this.state.openListe} />
                            </div>
                            <div className="form-group">
                                {this.state.produit && <button type="submit" className="btn btn-link btn-sm" >Ajouter</button>}
                                {this.props.ofs.length !== 0 &&
                                    <button type="button" className="btn btn-link btn-sm" onClick={this.handleSave}>Enregistrer</button>
                                }
                                {this.props.ofs.length !== 0 &&
                                    <button type="button" className="btn btn-link btn-sm" onClick={this.handleRemove}>Supprimer</button>
                                }

                            </div>

                        </form>

                    </div>
                    {/* <div className="col-sm" >
                        <button onClick={() => this.setState({ openListe: true })} > test</button>
                        <SearchProd onClick={this.switch} handleSelect={this.handleSelect} viewList={this.state.openListe} />
                        {this.state.openListe ? 'true' : 'false'}
                    </div> */}
                </div>


            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeProduit: state.produit.listeProduit,
        listeClientInput: clientOnlyChild(state),
        listeTypeTransport: state.generalData.listeTypeTransport,
        listeTypeCom: state.generalData.listeTypeCom,
        listeStade: state.generalData.listeStade,
        listeMetaux: state.generalData.listeMetaux,
        ofs: state.ofs.ofs,
        listetypeCommande: state.generalData.typeCommande,

    }
}
const mapDispatchToProps = {
    getListeProduit,
    getListeClient,
    getMetaux,
    getTypeTransport,
    getStade,
    addOf,
    updateOf,
    deleteOf,
    deleteOfs,
    getListeOf,
    setOfToEdit,
    getTypeCom,
    getTypeCommande,
}
export default connect(mapStateToProps, mapDispatchToProps)(Of)