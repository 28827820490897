import React, { Component } from 'react'
import { getStade, getMetaux, getTypeProduit, getCaGroupedByMonth, getComparaisonCaMois, getComparaisonCaAn, getAnalyseCa, getAnalyseMetal, getTypeCommande, getAnalyseCaParTranche, getAnalyseCaParTarif } from '../../actions'
import { connect } from 'react-redux'
import FilterSelect from '../../components/filterSelect'
import FilterInput from '../../components/filterInput'
import TreeClient from '../../components/treeClient'
import { Autocomplete, TabPanel } from '@material-ui/lab'
import TabContext from '@material-ui/lab/TabContext';
import { Tab, Tabs, TextField } from '@material-ui/core'
import BarChartCaAnnee from './BarChartCaAnnee'
import DataGridVente from './DataGridVente'
import VenteParTarifs from './VenteParTarif'
import ComparaisonCaMois from './ComparaisonCaMois'
import ComparaisonCaAn from './ComparaisonCaAn'


class AnalyseCA extends Component {
    today = new Date()
    temp = this.today.getFullYear() + '-' + ("0" + (this.today.getMonth() + 1)).slice(-2) + '-' + ("0" + this.today.getDate()).slice(-2)
    selectedEnCours = ''
    selectedLivre = ''
    selectedLivreExtensia = ''
    dataDevis = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'DEVIS' }, { id: 0, description: 'NORMAL' }]
    dataImperatif = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'IMPERATIF' }, { id: 0, description: 'NORMAL' }]
    dataTypeCommande = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'TOURNEE' }, { id: 0, description: 'NORMAL' }]

    currentYear = new Date().getFullYear();

    state = {
        stade: '',
        metal: '',
        type: '',
        ref: '',
        client: '',
        tournee: null,
        debut: `${this.currentYear - 1}-01-01`,
        fin: `${this.currentYear - 1}-12-31`,
        open: false,
        devis: null,
        openEditOf: false,
        imperatif: null,
        selectedTab: "0",
        typecommande: null,

    }

    componentDidMount() {
        this.props.getStade()
        this.props.getMetaux()
        this.props.getTypeProduit()
        this.props.getTypeCommande()
    }
    getFilter = () => {
        const data = {
            stade: this.state.stade,
            metal: this.state.metal,
            type: this.state.type,
            ref: this.state.ref,
            facture: this.state.facture,
            client: this.state.client,
            tournee: this.state.tournee ? this.state.tournee.id : 2,
            debut: this.state.debut,
            fin: this.state.fin,
            imperatif: this.state.imperatif ? this.state.imperatif.id : 2,
            type_commande: this.state.typecommande ? this.state.typecommande : 0,

        }
        return data
    }
    onChange = (data, labelState) => {
        const label = labelState.toLowerCase()
        this.setState({ [label]: data })  //, () => this.refreshLists()
    }
    handleChangeClient = (data) => {
        this.setState({ client: data })  //, () => this.refreshLists()
    }

    refreshLists = () => {
        const { selectedTab } = this.state;
        switch (selectedTab) {
            case "0":
            case "1":
                this.props.getAnalyseCa(this.getFilter());
                this.props.getAnalyseMetal(this.getFilter());
                this.props.getAnalyseCaParTranche(this.getFilter());
                this.props.getAnalyseCaParTarif(this.getFilter());
                break;
            case "2":
                this.props.getCaGroupedByMonth(this.getFilter());
                break;
            case "3":
                this.props.getComparaisonCaMois(this.getFilter());
                break;
            case "4":
                this.props.getComparaisonCaAn(this.getFilter());
                break;
            default:
                break;
        }
    }
    handleTabChange = (event, newValue) => {
        // console.log(this.state.selectedTab !== newValue.toString());
        if (this.state.selectedTab !== newValue.toString()) {
            this.setState({ selectedTab: newValue.toString() }, () => this.refreshLists());
        }
    };


    render() {
        const { caByMonth, analyseCa, analyseMetal, listeCaParTranche, analyseCaTarif, caComparaisonByMonth, caComparaisonByYear } = this.props;

        const { selectedTab } = this.state;
        return (
            <div>
                <div className='row ' >
                    <div className="col-auto" style={{ minWidth: 275, maxWidth: 275 }} >
                        <TreeClient handleChange={this.handleChangeClient} />
                    </div>

                    <div className="col-auto" >
                        <div className='form-group' style={{ width: "165px" }}>
                            <TextField type='date' value={this.state.debut} onChange={e => e.target.value && this.onChange(e.target.value, 'debut')} label='Debut' variant="outlined" margin="dense" />
                            <TextField type='date' value={this.state.fin} onChange={e => e.target.value && this.onChange(e.target.value, 'fin')} label='Fin' variant="outlined" margin="dense" />
                            <Autocomplete
                                options={this.dataTypeCommande}
                                getOptionLabel={(option) => option.description}
                                onChange={(e, values) => this.setState({ tournee: values })} //, () => this.refreshLists()
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: "165px" }}
                                renderInput={(params) => <TextField  {...params} label="Type Commande" variant="outlined" margin="dense" size='small' />}
                            />
                            <Autocomplete
                                options={this.dataImperatif}
                                getOptionLabel={(option) => option.description}
                                onChange={(e, values) => this.setState({ imperatif: values })} //, () => this.refreshLists()
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: "165px" }}
                                renderInput={(params) => <TextField  {...params} label="Impératif" variant="outlined" margin="dense" size='small' />}
                            />

                        </div>

                    </div>
                    <div className="col-sm" style={{ padding: '0 2px' }}>
                        <FilterSelect label='Type Commande' labelId='id' labelDescription="label" onChange={(e) => { this.onChange(e, 'typeCommande') }} options={this.props.listeTypeCommande} />
                    </div>
                    <div className="col-sm" >
                        <FilterSelect label='Stade' labelId='id_stade' onChange={this.onChange} options={this.props.listeStade} />
                    </div>
                    <div className="col-sm" >
                        <FilterSelect label='Metal' labelId='id_type' onChange={this.onChange} options={this.props.listeMetaux} />
                    </div>
                    <div className="col-sm" >
                        <FilterSelect label='Type' labelId='id_typ_prod' onChange={this.onChange} options={this.props.listeTypeProduit} />
                    </div>
                    <div className="col-auto" >
                        <FilterInput label='Ref' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-sm" >
                        <button style={{ minHeight: 200 }} className='btn btn-primary btn-lg' onClick={() => this.refreshLists()}>Afficher</button>
                    </div>


                </div>
                <TabContext value={selectedTab}>
                    <div>
                        <Tabs value={selectedTab} onChange={this.handleTabChange} centered>
                            <Tab label="Analyse Top Vente" value="0" />
                            <Tab label="Analyse Vente" value="1" />
                            <Tab label="CA Par Mois" value="2" />
                            <Tab label="Comparaison Par Mois" value="3" />
                            <Tab label="Comparaison Par An" value="4" />
                        </Tabs>

                        <TabPanel value={"0"} index={"0"}>
                            {analyseCa && analyseCa.length > 0 && (
                                <DataGridVente data={analyseCa} dataMetal={analyseMetal} getFilter={this.getFilter} />
                            )}
                        </TabPanel>
                        <TabPanel value={"1"} index={"1"}>
                            {analyseCa && analyseCa.length > 0 && (
                                <VenteParTarifs analyseCaTranche={listeCaParTranche} analyseCaTarif={analyseCaTarif} getFilter={this.getFilter} />
                            )}
                        </TabPanel>
                        <TabPanel value={"2"} index={"2"}>
                            {caByMonth && caByMonth.length > 0 && (
                                <BarChartCaAnnee data={caByMonth} />
                            )}
                        </TabPanel>
                        <TabPanel value={"3"} index={"3"}>
                            {caComparaisonByMonth && caComparaisonByMonth.current && caComparaisonByMonth.current.length > 0 && (
                                <ComparaisonCaMois data={caComparaisonByMonth} />
                            )}
                        </TabPanel>
                        <TabPanel value={"4"} index={"4"}>
                            {caComparaisonByYear && caComparaisonByYear.ca && caComparaisonByYear.ca.length > 0 && (
                                <ComparaisonCaAn data={caComparaisonByYear} />
                            )}
                        </TabPanel>
                    </div>
                </TabContext>

            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeStade: state.generalData.listeStade,
        listeTypeProduit: state.generalData.listeTypeProduit,
        listeMetaux: state.generalData.listeMetaux,
        listeTypeCommande: state.generalData.typeCommande,
        caByMonth: state.analyse.caByMonth,
        caComparaisonByMonth: state.analyse.caComparaisonByMonth,
        caComparaisonByYear: state.analyse.caComparaisonByYear,
        analyseCa: state.analyse.analyseCa,
        analyseCaTarif: state.analyse.analyseCaTarif,
        analyseMetal: state.analyse.analyseMetal,
        listeCaParTranche: state.analyse.analyseCaTranche,
        listeOfs: state.ofs.listeOf,

    }
}
const mapDispatchToProps = {
    getStade,
    getMetaux,
    getTypeProduit,
    getCaGroupedByMonth,
    getAnalyseCa,
    getAnalyseMetal,
    getTypeCommande,
    getAnalyseCaParTranche,
    getAnalyseCaParTarif,
    getComparaisonCaMois,
    getComparaisonCaAn,
}
export default connect(mapStateToProps, mapDispatchToProps)(AnalyseCA)