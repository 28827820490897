import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import MaterialTable from 'material-table';
import { Typography, Paper, Button, TextField } from '@material-ui/core';
import axios from 'axios';
import { apiATC, localization } from '../../config';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import { getStade, getMetaux, getTypeProduit, getStatut, getTypeCommande } from '../../actions';
import { addDayToToday, formatEuro } from '../../helpers/functions';
import FilterSelect from '../filterSelect';
import TreeClient from '../treeClient';

const OfParStatut = ({ listeStade, listeTypeProduit, listeMetaux, listeStatut, listeTypeCommande, getStade, getMetaux, getTypeProduit, getStatut, getTypeCommande }) => {
    useEffect(() => {
        getStade();
        getMetaux();
        getTypeProduit();
        getStatut();
        getTypeCommande();
    }, [getStade, getMetaux, getTypeProduit, getStatut, getTypeCommande]);

    // Colonnes pour MaterialTable
    const columns = [
        { title: 'OF', field: 'num_sachet', align: 'center', width: '25%' },
        { title: 'Bijouterie', field: 'bijouterie', align: 'center', width: '20%' },
        { title: 'Type', field: 'type_produit', align: 'center', width: '20%' },
        { title: 'Référence', field: 'ref', align: 'center', width: '35%' },
        { title: 'Prix', field: 'prix_fact', type: 'currency', currencySetting: { locale: 'fr', currencyCode: 'EUR' }, align: 'center', width: '20%' },
    ];
    // États pour stocker les données, les graphiques et la sélection
    const [pieChartData, setPieChartData] = useState(null);
    const [titre, setTitre] = useState("");
    const [selectedData, setSelectedData] = useState([]);

    const currentYear = new Date().getFullYear();
    const [filters, setFilters] = useState({
        client: '',
        debut: `${currentYear}-01-01`,
        fin: addDayToToday(30),
        devis: null,
        facon: null,
        imperatif: null,
        tournee: null,
        typeResa: null,
        stade: '',
        metal: '',
        type: '',
        statut: '',
        ref: '',
        of: '',
        facture: '',
        ref_clt: '',
        typecommande: null,
    });

    const dataDevis = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'DEVIS' }, { id: 0, description: 'NORMAL' }];
    const dataImperatif = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'IMPERATIF' }, { id: 0, description: 'NORMAL' }];
    const dataTypeCommande = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'TOURNEE' }, { id: 0, description: 'NORMAL' }];
    const dataFacon = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'A FACON' }, { id: 0, description: 'NORMAL' }];
    const dataResa = [{ id: 2, description: 'NON RESERVE' }, { id: 1, description: 'RESERVATION' }, { id: 0, description: 'TOUT' }];

    const onChange = (data, labelState) => {
        const label = labelState.toLowerCase();
        setFilters({ ...filters, [label]: data });
    };

    const handleChangeClient = (data) => {
        setFilters({ ...filters, client: data });
    };
    // Charger les données à partir de l'API avec Axios
    useEffect(() => {
        const fetchData = async () => {
            try {
                const initialFilters ={
                    debut: `${currentYear}-01-01`,
                    fin: addDayToToday(30),
                }
                const response = await axios.post(`${apiATC}/of/liste.php`, initialFilters);
                const data = response.data;

                // Préparer les données pour le Doughnut chart
                const chartData = {
                    labels: data.map(item => `${item.Count} ${item.nom} (${formatEuro(item.sum_prix_fact)})`), // Count +Nom + somme des prix facturés
                    datasets: [
                        {
                            data: data.map(item => item.sum_prix_fact), // Valeur pour chaque tranche
                            backgroundColor: [
                                'rgba(255, 0, 0, 0.7)',     // Vibrant red
                                'rgba(241, 142, 28, 0.5)',    // Orange
                                'rgba(244, 229, 0, 1)',    // Yellow
                                'rgba(54, 162, 235, 0.9)',   // Bleu
                               'rgba(0, 255, 0, 0.9)',      // Light green
                               'rgba(0, 255, 0, 0.9)',      // Light green

                            ],
                            borderWidth: 1,
                        }
                    ],
                    ofsData: data.map(item => item.ofs), // Stockage des données ofs pour chaque tranche
                };

                setPieChartData(chartData);
            } catch (error) {
                console.error('Erreur lors du chargement des données', error);
            }
        };

        fetchData();
    }, []);
    const refreshLists = async () => {
        try {
            const nonEmptyFilters = Object.fromEntries(
                Object.entries(filters).filter(([_, v]) => v !== null && v !== '')
            );
            const response = await axios.post(`${apiATC}/of/liste.php`, nonEmptyFilters);
            const data = response.data;

            // Préparer les données pour le Doughnut chart
            const chartData = {
                labels: data.map(item => `${item.Count} ${item.nom} (${formatEuro(item.sum_prix_fact)})`), // Count +Nom + somme des prix facturés
                datasets: [
                    {
                        data: data.map(item => item.sum_prix_fact), // Valeur pour chaque tranche
                        backgroundColor: [
                            'rgba(255, 0, 0, 0.7)',     // Vibrant red
                            'rgba(241, 142, 28, 0.5)',    // Orange
                            'rgba(244, 229, 0, 1)',    // Yellow
                            'rgba(54, 162, 235, 0.9)',   // Bleu
                            'rgba(0, 255, 0, 0.9)',      // Light green
                            'rgba(0, 255, 0, 0.9)',      // Light green
                        ],
                        borderWidth: 1,
                    }
                ],
                ofsData: data.map(item => item.ofs), // Stockage des données ofs pour chaque tranche
            };

            setPieChartData(chartData);
        } catch (error) {
            console.error('Erreur lors du chargement des données', error);
        }
    };

    // Gérer l'événement de clic sur une tranche du PieChart
    const handleChartClick = (event, elements) => {
        // console.log(pieChartData);
        
        if (elements.length > 0) {

            const index = elements[0]._index;
            const selectedOfs = pieChartData.ofsData[index]; // Récupérer les ofs associés à la tranche cliquée

            setSelectedData(selectedOfs); // Mettre à jour les données de la table
            setTitre(pieChartData.labels[index])
        }
    }
    // Calculate the total sum of pieChartData.datasets[0].data
    const totalSum = pieChartData? pieChartData.datasets[0].data.reduce((acc, curr) => acc + curr, 0):'';

    // Format the title with the total sum
    const legendTitle = `Reste à Livrer : ${formatEuro(totalSum)}`;
    return (
        <div >
            <div className='row'>
                <div className="col-auto">
                    <TreeClient handleChange={handleChangeClient} />
                </div>

                <div className="col-sm" style={{ padding: '4px' }}>
                    <TextField margin='dense' fullWidth type='date' value={filters.debut} onChange={e => e.target.value && onChange(e.target.value, 'debut')} label='Debut' variant="outlined" size='small' />
                    <TextField margin='dense' fullWidth type='date' value={filters.fin} onChange={e => e.target.value && onChange(e.target.value, 'fin')} label='Fin' variant="outlined" size='small' />
                    <Autocomplete
                        options={dataImperatif}
                        getOptionLabel={(option) => option.description}
                        onChange={(e, values) => setFilters({ ...filters, imperatif: values.id })}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        renderInput={(params) => <TextField margin='dense'  {...params} label="Impératif" variant="outlined" size='small' />}
                    />
                    <Autocomplete
                        options={dataDevis}
                        getOptionLabel={(option) => option.description}
                        onChange={(e, values) => setFilters({ ...filters, devis: values?values?values.id:null:null })}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        renderInput={(params) => <TextField {...params} label="Devis" variant="outlined" margin='dense' size='small' />}
                    />
                </div>
                <div className="col-sm" style={{ padding: '4px' }}>
                    <Autocomplete
                        options={dataTypeCommande}
                        getOptionLabel={(option) => option.description}
                        onChange={(e, values) => setFilters({ ...filters, tournee: values?values.id:null })}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        renderInput={(params) => <TextField margin='dense' {...params} label="Tournée" variant="outlined" size='small' />}
                    />
                    <Autocomplete
                        options={dataFacon}
                        getOptionLabel={(option) => option.description}
                        onChange={(e, values) => setFilters({ ...filters, facon: values?values.id:null })}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        renderInput={(params) => <TextField margin='dense'  {...params} label="Façon" variant="outlined" size='small' />}
                    />
                    <Autocomplete
                        options={dataResa}
                        getOptionLabel={(option) => option.description}
                        onChange={(e, values) => setFilters({ ...filters, type_resa: values?values.id:null })}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        renderInput={(params) => <TextField margin='dense' {...params} label="Reservation" variant="outlined" size='small' />}
                    />
                </div>

                <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                    <FilterSelect label='Type Commande' labelId='id' labelDescription="label" onChange={(e) => { onChange(e, 'typeCommande') }} options={listeTypeCommande} />
                </div>
                <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                    <FilterSelect label='Stade' labelId='id_stade' onChange={onChange} options={listeStade} />
                </div>
                <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                    <FilterSelect label='Metal' labelId='id_type' onChange={onChange} options={listeMetaux} />
                </div>
                <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                    <FilterSelect label='Type' labelId='id_typ_prod' onChange={onChange} options={listeTypeProduit} />
                </div>
                <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                    <FilterSelect label='Statut' labelId='Statut' onChange={onChange} options={listeStatut} labelDescription='OF_interne' />
                </div>
                <div className="col-auto" style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    <Button variant="contained" color="primary" onClick={() => refreshLists()}>Afficher</Button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <Paper style={{ padding: 20 }}>
                        <Typography variant="h5" align='center' gutterBottom>
                            Répartition des OFs par Statut
                        </Typography>
                        {pieChartData && (
                            <Doughnut
                                data={pieChartData}
                                options={{
                                    onClick: handleChartClick,
                                    legend: {
                                        position: 'left',
                                        labels: {
                                            fontSize: 20 // Adjust the size as needed
                                        }
                                    },title: {
                                        display: true,
                                        text: legendTitle,
                                        fontSize: 20,
                                        position: 'top'
                                    }
                                }}
                            />
                        )}
                    </Paper>
                </div>
                <div className="col-6">
                    {selectedData && selectedData.length !== 0 &&
                        <MaterialTable
                            style={{ fontSize: 12 }}
                            columns={columns}
                            data={selectedData}
                            title={titre}
                            options={{
                                search: false,
                                paging: false,
                                maxBodyHeight: 'calc(100vh - 300px)',
                                headerStyle: {
                                    backgroundColor: '#a0d1ff',
                                    fontWeight: 'bold'
                                }
                            }}
                            localization={localization}
                        />
                    }
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = (state) => {
    return {
        listeStade: state.generalData.listeStade,
        listeTypeProduit: state.generalData.listeTypeProduit,
        listeMetaux: state.generalData.listeMetaux,
        listeStatut: state.generalData.listeStatut,
        listeTypeCommande: state.generalData.typeCommande,
    }
}

const mapDispatchToProps = {
    getStade,
    getMetaux,
    getTypeProduit,
    getStatut,
    getTypeCommande,
}

export default connect(mapStateToProps, mapDispatchToProps)(OfParStatut);
