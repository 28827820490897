export const getValueFromId = (id, tab, idKey = 'id_cours', valueKey = 'valeur') => {
    if (tab && id) {
        for (let i = 0; i < tab.length; i++) {
            if (tab[i][idKey] === id) return tab[i][valueKey];
        }
    }
    return 1;
}
export const preventEnter = (e) => {
    e.key === 'Enter' && e.preventDefault()
}
/* eslint-disable eqeqeq */
export const inArray = (id, tab) => {
    if (tab && id) {
        for (let i = 0; i < tab.length; i++) {
            if (tab[i] == id) return true
        }
    }
    return false;
}
export function euroFormat(montant, minimumFractionDigits = 0) {
    // Vérifier si montant est un nombre
    if (typeof montant !== 'number') {
        // Si montant n'est pas un nombre, tenter de le convertir en nombre
        montant = parseFloat(montant);
    }

    // Vérifier si montant est un nombre valide
    if (!isNaN(montant)) {
        // Convertir le montant en chaîne de caractères avec le nombre approprié de chiffres après la virgule
        const montantAjuste = montant.toFixed(minimumFractionDigits);

        // Formater le montant ajusté en utilisant Intl.NumberFormat
        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits
        }).format(montantAjuste);
    } else {
        // Si montant n'est pas un nombre valide, retourner une chaîne vide ou une autre valeur par défaut
        return '';
    }
}
export function dollarFormat(montant, minimumFractionDigits = 0) {
    // Vérifier si montant est un nombre
    if (typeof montant !== 'number') {
        // Si montant n'est pas un nombre, tenter de le convertir en nombre
        montant = parseFloat(montant);
    }

    // Vérifier si montant est un nombre valide
    if (!isNaN(montant)) {
        // Convertir le montant en chaîne de caractères avec le nombre approprié de chiffres après la virgule
        const montantAjuste = montant.toFixed(minimumFractionDigits);

        // Formater le montant ajusté en utilisant Intl.NumberFormat
        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits
        }).format(montantAjuste);
    } else {
        // Si montant n'est pas un nombre valide, retourner une chaîne vide ou une autre valeur par défaut
        return '';
    }
}



export const getListForSqlIn = (data = [], labelId = '') => {
    if (!data.length) return ''
    return data.reduce((ac, item) => {
        const toPush = labelId !== '' ? item[labelId] : item
        if (ac !== '') ac += ',' + toPush
        else ac = toPush
        return ac
    }, '')
}
export const getTodaySqlFormat = (date = new Date()) => {
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2)
}
export const addDayToToday = (jours = 0) => {
    let today = new Date()
    today.setDate(today.getDate() + parseInt(jours));
    return getTodaySqlFormat(new Date(today.getFullYear(), today.getMonth() + 1, 0))
}
export const AjouterJourDateOuvrable = (jours = 0) => {
    var dateActuelle = new Date();
    dateActuelle.setDate(dateActuelle.getDate() + parseInt(jours));
    while (dateActuelle.getDay() === 0 || dateActuelle.getDay() === 6) {
        dateActuelle.setDate(dateActuelle.getDate() + 1);
    }

    // Format de retour
    var annee = dateActuelle.getFullYear();
    var mois = ("0" + (dateActuelle.getMonth() + 1)).slice(-2);
    var jour = ("0" + dateActuelle.getDate()).slice(-2);

    return annee + '-' + mois + '-' + jour;
}
export const dateToEnglish = (date)=>{
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export const dateToFrench = (dateString, separator = '-') => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Ajoutez 1 car getMonth() retourne les mois de 0 à 11
    const year = date.getFullYear().toString().slice(-2); // Obtenez les deux derniers chiffres de l'année

    // Ajoutez un zéro en tête si le jour ou le mois est inférieur à 10
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    // Utilisez le séparateur spécifié ou '-' par défaut
    const formattedDate = `${formattedDay}${separator}${formattedMonth}${separator}${year}`;

    return formattedDate;
};
export const roundPrecision2 = (nb = 0) => {
    return Math.ceil(nb * 100) / 100
}
export const createPrixPierre = (pierre, pds, euroDollar) => {
    if (Number(pierre.euro) !== 0) return Math.ceil(parseFloat(pierre.euro) * parseFloat(pds));
    else return Math.ceil(parseFloat(pierre.dollar) * parseFloat(pds) / parseFloat(euroDollar));
}

export const createPrixCoefPierre = (pierre, pds, euroDollar) => {
    return Math.ceil(createPrixPierre(pierre, pds, euroDollar) * parseFloat(pierre.coeff));
}
export const createDefaultDescPierre = (pierre) => {
    let dimension = '', diametre = '', poids = '', description = '';
    return Object.values(pierre).map(p => {

        description = p.type + ' ' + p.laforme + ' ' + p.couleur + ' ';
        if (Number(p.typ_dim) === 0) {
            poids = p.dim1
            diametre = " Ø" + p.dim2;
            description += poids + ' ' + diametre;

        }
        else if (Number(p.typ_dim) === 1 || Number(p.typ_dim) === 3) {
            dimension = p.dim1 + "x" + p.dim2;
            poids = p.dim3
            description += dimension;
        }
        else if (Number(p.typ_dim) === 2) {
            dimension = p.dim1 + "x" + p.dim2 + "x" + p.dim3;
            description += dimension;
            poids = '';
        }
        p.descriptionPierre = poids !== '' ? description + ' ' + p.laqual + ' = ' + poids : description + ' ' + p.laqual;
        p.lePoids = poids;
        return p
    })
}
export const createDescPierre = (pierre, pds) => {

    let dimension = '', diametre = '', desc = '';
    if (Number(pierre.typ_dim) === 0) {
        dimension = pierre.dim1 + " Ø " + pierre.dim2;
    }
    else if (Number(pierre.typ_dim) === 1) {
        dimension = pierre.dim1 + " x " + pierre.dim2;
    }
    else if (Number(pierre.typ_dim) === 2) {
        dimension = pierre.dim1 + " x " + pierre.dim2 + " x " + pierre.dim3;
    }
    if (Number(pierre.type_pier) === 1017) desc = pierre.type + ' ' + pierre.couleur + ' ' + pierre.laforme + ' ' + diametre;
    else if (Number(pierre.type_pier) === 1006) desc = pierre.type + ' ' + pierre.laforme + ' ' + pierre.couleur + ' ' + dimension;
    else desc = pierre.type + ' ' + pierre.laforme + ' ' + pierre.couleur + ' ' + dimension + ' = ' + pds + ' - ' + pierre.laqual;

    if (pierre.certificat !== undefined && pierre.certificat !== "") desc += ' / ' + pierre.certificat;
    // console.log(pierre);
    return desc;
}
export const arrodirDizainesup = (nb) => {
    return (parseInt(nb) / 10 + 1) * 10;
}
export const finaliserPrix = (nb) => {
    if (parseInt(nb) % 1000 === 0 && nb !== 0) nb = parseInt(nb) - 10;
    return nb;
}
export const prixParType = (typeTarif, totalR, totalV) => {
    // typeTarif = prod['id_tarif_produit'];

    // 			totalR = Math.round(revientMFS+revientEntourage+revientCentre);
    // 			totalV = Math.round(revientMFS*1.59+prixEntourage+prixCentre);
    let total = []
    let piece_vente = 0, piece_vente_o = 0, coefRev = 1
    switch (Number(typeTarif)) {
        case 1:
            piece_vente = finaliserPrix(arrodirDizainesup(parseInt(totalV) * 1.08 + 20));
            piece_vente_o = finaliserPrix(arrodirDizainesup(Math.round((parseInt(totalV) + 20) / 0.9)));
            //console.log(arrodirDizainesup(parseInt(totalV)*1.08+20));
            break;

        case 2:
            piece_vente = finaliserPrix(Math.round(parseInt(totalV) + 20));
            piece_vente_o = finaliserPrix(arrodirDizainesup(parseInt(piece_vente) / 0.9));
            break;

        case 3:
            if (totalR / 0.65 > parseInt(totalV)) piece_vente = finaliserPrix(Math.round(parseInt(totalV)) + 20);
            else piece_vente = finaliserPrix(Math.round(totalR / 0.65) + 20);
            piece_vente_o = finaliserPrix(arrodirDizainesup(parseInt(piece_vente) / 0.9));
            break;

        case 4:
            if (totalR < 1000) coefRev = 0.67;
            else if (totalR < 3500) coefRev = 0.68;
            else coefRev = 0.70;
            piece_vente = finaliserPrix(Math.round(parseInt(totalR) / coefRev) + 20);
            piece_vente_o = finaliserPrix(arrodirDizainesup(parseInt(piece_vente) / 0.9));
            break;

        case 5:
            piece_vente = finaliserPrix(Math.round(parseInt(totalR) * 1.2 + 20));
            piece_vente_o = finaliserPrix(arrodirDizainesup(parseInt(piece_vente) / 0.9));
            break;

        case 6:
            if (parseInt(totalR) < 3000) coefRev = 1.25;
            else coefRev = 1.27;
            piece_vente = finaliserPrix(Math.round(parseInt(totalR) * coefRev));
            piece_vente_o = finaliserPrix(arrodirDizainesup(parseInt(piece_vente) / 0.9));
            break;

        case 7:
            if (parseInt(totalR) < 1510) coefRev = 1.22;
            else coefRev = 1.2;
            piece_vente = finaliserPrix(Math.round(parseInt(totalR) * coefRev + 20));
            piece_vente_o = finaliserPrix(arrodirDizainesup(parseInt(piece_vente) / 0.9));
            break;


        default:
            break;
    }
    total.prix = piece_vente;
    total.prix_o = piece_vente_o;
    return total;

}
export const calculateFriday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Dimanche est 0, Lundi est 1, ..., Vendredi est 5
    const friday = new Date(today); // Cloner la date actuelle
    const daysToAdd = dayOfWeek === 5 ? 0 : 5 - dayOfWeek; // Nombre de jours à ajouter pour arriver à vendredi
    friday.setDate(today.getDate() + daysToAdd); // Définir la date sur vendredi
    return formatDate(friday); // Formater la date au format 'YYYY-MM-DD'
}
export const formatDate = (date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
        month = `0${month}`; // Ajouter un zéro devant le mois si nécessaire pour le format 'MM'
    }
    let day = date.getDate();
    if (day < 10) {
        day = `0${day}`; // Ajouter un zéro devant le jour si nécessaire pour le format 'DD'
    }
    return `${year}-${month}-${day}`;
}

export const exportCsv = (allColumns, allData, name = 'ofs') => {
    const fields = allColumns.map(columnDef => columnDef.field);

    // Créer le contenu CSV avec l'encodage UTF-8
    let csvContent = '\uFEFF'; // BOM (Byte Order Mark) UTF-8
    csvContent += allColumns.map(columnDef => columnDef.title).join(';') + '\n';
    allData.forEach(rowData => {
        fields.forEach(field => {
            if (rowData[field] !== undefined && rowData[field] !== null) {
                csvContent += `"${rowData[field].toString().replace(/"/g, '""')}"`;
            }
            csvContent += ';';
        });
        csvContent += '\n';
    });

    // Convertir la chaîne de caractères en Blob avec l'encodage UTF-8
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Créer un lien de téléchargement et déclencher le téléchargement du fichier CSV
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", name + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export const verifSiNombre = (nombre) => {
    return (/^[0-9.]*$/.test(nombre))
}

export const getVendrediSemaineEnCours = () => {
    const today = new Date();
    const jourActuel = today.getDay(); // 0 pour dimanche, 1 pour lundi, ..., 6 pour samedi
    const diffJours = jourActuel === 0 ? 6 : 5 - jourActuel; // Nombre de jours à ajouter pour aller à vendredi
    const dateVendrediSemaineEnCours = new Date(today);
    dateVendrediSemaineEnCours.setDate(today.getDate() + diffJours);
    return dateVendrediSemaineEnCours.toISOString().split('T')[0]; // Format YYYY-MM-DD
};
// Fonction pour obtenir la date de début de la semaine
export const getStartDateOfWeek = (week) => {
    const [year, weekNumber] = week.split("-W");
    const janFirst = new Date(year, 0, 1);
    const startWeek = new Date(janFirst.getTime() + ((weekNumber - 1) * 7) * 24 * 60 * 60 * 1000);
    return startWeek.toISOString().slice(0, 10);
}

// Fonction pour obtenir la date de fin de la semaine
export const getEndDateOfWeek = (week) => {
    const startWeek = new Date(getStartDateOfWeek(week));
    const endWeek = new Date(startWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
    return endWeek.toISOString().slice(0, 10);
}

// Fonction pour obtenir la date de début du mois
export const getStartDateOfMonth = (month) => {
    return `${month}-01`;
}

// Fonction pour obtenir la date de fin du mois
export const getEndDateOfMonth = (month) => {
    const [year, monthNumber] = month.split("-");
    const lastDayOfMonth = new Date(year, monthNumber, 0).getDate();
    return `${month}-${lastDayOfMonth}`;
}

// Fonction pour obtenir la date de début de l'année
export const getStartDateOfYear = (year) => {
    return `${year}-01-01`;
}

// Fonction pour obtenir la date de fin de l'année
export const getEndDateOfYear = (year) => {
    return `${year}-12-31`;
}
export const formatEuro =(valeur)=>{
    return valeur.toLocaleString('fr-FR', {style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0})
}