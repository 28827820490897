import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { euroFormat } from '../../helpers/functions';

const ComparaisonCaAn = ({ data }) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [bestYear, setBestYear] = useState("");
    const [average, setAverage] = useState(0);
    const [standardDeviation, setStandardDeviation] = useState(0);

    const generateLabel = () => {


        // Retourne le texte formaté
        return `Meilleure année : ${bestYear}  - Moyenne: ${euroFormat(average)} - Écart type: ${euroFormat(standardDeviation)}`;
    }

    const titleBar = generateLabel();
    useEffect(() => {
        if (data && data.ca && data.avoir) {
            const labels = data.ca.map(item => item.annee);
            const totalCA = data.ca.map(item => parseFloat(item.totalCA));
            const totalAvoir = data.avoir.map(item => parseFloat(item.totalAvoir));
            const differences = totalCA.map((ca, index) => ca - totalAvoir[index]);

            // Calcul de la meilleure année
            const maxDifference = Math.max(...differences);
            const bestYearIndex = differences.indexOf(maxDifference);
            const bestYearLabel = labels[bestYearIndex];
            setBestYear(bestYearLabel + ' : ' + euroFormat(maxDifference));

            // Calcul de la moyenne
            const sum = differences.reduce((acc, curr) => acc + curr, 0);
            const avg = sum / differences.length;
            setAverage(avg);

            // Calcul de l'écart type
            const squaredDiffs = differences.map(diff => Math.pow(diff - avg, 2));
            const variance = squaredDiffs.reduce((acc, curr) => acc + curr, 0) / squaredDiffs.length;
            const stdDev = Math.sqrt(variance);
            setStandardDeviation(stdDev);

            const updatedChartData = {
                labels: labels,
                datasets: [
                    {
                        label: "CA (Avoir Déduit)",
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: "rgba(75,192,192,1)",
                        hoverBackgroundColor: "rgba(75,192,192,0.6)",
                        hoverBorderColor: "rgba(75,192,192,1)",
                        data: differences,
                        stack: 'Stack 0',
                    },
                    {
                        label: "Avoir",
                        backgroundColor: "rgba(255,99,132,0.4)",
                        borderColor: "rgba(255,99,132,1)",
                        hoverBackgroundColor: "rgba(255,99,132,1)",
                        hoverBorderColor: "rgba(255,99,132,1)",
                        data: totalAvoir,
                        stack: 'Stack 0',
                    },
                ]
            };
            setChartData(updatedChartData);
        }
    }, [data]);
    const options = {
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Année"
                },
                stacked: true,
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Montant"
                },
                ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                        return euroFormat(value);
                    }
                },
                stacked: true,
            }]
        },
        title: {
            display: true,
            text: titleBar
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += euroFormat(Math.round(tooltipItem.yLabel * 100) / 100);
                    return label;
                },
                afterBody: function (tooltipItem, data) {
                    const total = data.datasets[0].data[tooltipItem[0].index] + data.datasets[1].data[tooltipItem[0].index];
                    return 'Total: ' + euroFormat(total);
                }
            }
        },
        elements: {
            line: {
                fill: false,
            },
            point: {
                radius: 0 // Masquer les points sur la ligne
            }
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>

            <Bar data={chartData} options={options} height={80} />

        </div>
    );
};

export default ComparaisonCaAn;
