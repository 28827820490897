import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { Doughnut } from 'react-chartjs-2';
import { euroFormat } from '../../helpers/functions';
import Axios from 'axios';
import { meekroDB } from '../../config';
import PopupTable from '../../components/PopupTable';

const DataGridVente = ({ data, dataMetal, getFilter }) => {
    const theme = createMuiTheme({
        palette: {
            primary: blue,
        },
        typography: {
            fontFamily: 'Arial, sans-serif',
        },
    });

    const [columnsData, setColumnsData] = useState(null);
    const [title, setTitle] = useState(null);
    const [dataDetail, setDataDetail] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);

    const columns = [
        { title: 'Qte', field: 'Qte', type: 'numeric', align: 'center' },
        { title: 'Type Produit', field: 'Type Produit' },
        { title: 'Référence', field: 'Référence', type: 'numeric', align: 'center' },
        { title: 'Désignation', field: 'Désignation' },
        { title: 'CA HT', field: 'CA HT', type: 'numeric', align: 'center', render: rowData => euroFormat(rowData['CA HT']) },
        { title: 'Prix de Revient', field: 'Prix de Revient', type: 'numeric', align: 'center', render: rowData => euroFormat(rowData['Prix de Revient'], 0) },
        { title: '% Marge', field: 'Pourcentage', type: 'numeric', align: 'center' },
    ];
    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    const caHtByTypeProduit = data.reduce((acc, item) => {
        const typeProduit = item['Type Produit'];
        const caHt = parseFloat(item['CA HT']);
        acc[typeProduit] = (acc[typeProduit] || 0) + Math.ceil(caHt);
        return acc;
    }, {});

    // const caHtByTypeProduit = (data) => {
    //     const produitsParType = {};

    //     // Boucle à travers les données pour regrouper les produits par type de produit
    //     data.forEach(item => {
    //         const id_typ_prod = item['id_typ_prod']; // Utilise l'identifiant du type de produit existant
    //         const typeProduit = item['Type Produit'];
    //         const caHt = parseFloat(item['CA HT']);

    //         // Vérifie si le type de produit existe déjà dans l'objet produitsParType
    //         if (!produitsParType[id_typ_prod]) {
    //             // Si le type de produit n'existe pas, crée un nouvel objet pour ce type de produit
    //             produitsParType[id_typ_prod] = {
    //                 id_typ_prod: id_typ_prod,
    //                 typeProduit: typeProduit,
    //                 totalHT: 0
    //             };
    //         }

    //         // Ajoute le montant CA HT au total HT du type de produit correspondant
    //         produitsParType[id_typ_prod].totalHT += caHt;
    //     });

    //     // Convertit l'objet produitsParType en un tableau d'objets
    //     const resultat = Object.values(produitsParType);

    //     return resultat;
    // };
    const caHtByTypeMetal = dataMetal.reduce((acc, item) => {
        const description = item['description'];
        const Qte = parseFloat(item['Qte']);
        acc[description] = (acc[description] || 0) + Math.ceil(Qte);
        return acc;
    }, {});


    const totalProduit = Object.values(caHtByTypeProduit).reduce((total, caHt) => total + caHt, 0);

    const dataTypeProduit = {
        labels: Object.keys(caHtByTypeProduit).map(typeProduit => `${typeProduit} (${((caHtByTypeProduit[typeProduit] / totalProduit) * 100).toFixed(0)}% du CA : ${euroFormat(caHtByTypeProduit[typeProduit])})`),
        datasets: [
            {
                data: Object.values(caHtByTypeProduit),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.7)',
                    'rgba(54, 162, 235, 0.7)',
                    'rgba(255, 206, 86, 0.7)',
                    'rgba(240, 10, 10, 0.7)',
                    'rgba(120, 206, 10, 0.7)',
                    'rgba(187, 88, 210, 0.7)',
                    'rgba(50, 168, 82, 0.7)',
                    'rgba(255, 159, 64, 0.7)',
                    'rgba(75, 192, 192, 0.7)',
                    'rgba(0, 123, 255, 0.7)',
                    // Ajoutez des couleurs supplémentaires au besoin
                ],
            },
        ],
    };


    const totalMetal = Object.values(caHtByTypeMetal).reduce((total, quantity) => total + quantity, 0);
    const dataCouleurOr = {
        labels: Object.keys(caHtByTypeMetal).map(description => `${description} (${((caHtByTypeMetal[description] / totalMetal) * 100).toFixed(0)}% du CA : ${euroFormat(caHtByTypeMetal[description])})`),
        datasets: [
            {
                data: Object.values(caHtByTypeMetal),
                backgroundColor: Object.keys(caHtByTypeMetal).map((description, index) => {
                    // Déterminez la couleur en fonction du type de métal
                    switch (description) {
                        case 'OR ROSE':
                            return 'rgba(247, 175, 189, 0.7)'; // Rose
                        case 'OR BLANC':
                            return 'rgba(219, 219, 219, 0.7)'; // Gris
                        case 'OR JAUNE':
                            return 'rgba(255, 239, 119, 0.7)'; // Jaune
                        case 'ARGENT':
                            return 'rgba(54, 162, 235, 0.7)'; // Argent
                        case 'PLATINE':
                            return 'rgba(255, 206, 86, 0.7)'; // Platine
                        case 'Autre':
                            return 'rgba(0, 0, 0, 0.7)'; // Autre
                        case 'BRONZE':
                            return 'rgba(171, 145, 132, 0.7)'; // Bronze
                        // Ajoutez d'autres cas pour les autres types de métal au besoin
                        default:
                            return 'rgba(255, 99, 132, 0.7)'; // Couleur par défaut
                    }
                }),
            },
        ],
    };

    const handleDetailClick = (segmentData, typeDetail) => {
        let params = "";
        switch (typeDetail) {
            case "idp":
                params = `&idp=${segmentData.idp}`;
                break;
            case "type":
                params = `&type=${segmentData.type}`;
                break;
            case "metal":
                params = `&metal=${segmentData.metal}`;
                break;
            default:
                params = "";
        }
        Axios.post(`${meekroDB}/analyse/analyse_ca.php?action=top_vente${params}`, getFilter())
            .then(response => {
                const cols = [
                    { title: 'Ref', field: 'Référence', align: 'center', type: "numeric" },
                    { title: 'Designation', field: 'Désignation', align: 'center' },
                    { title: 'Vente HT', field: 'totht', type: "numeric", align: 'center', render: rowData => euroFormat(rowData.totht) },
                    { title: 'Client', field: 'nom_agence', align: 'center' },
                    { title: 'Métal', field: 'couleurOr', align: 'center' },

                ];
                setColumnsData(cols);
                setTitle(`Détail Vente : ${segmentData.Référence} ${segmentData.Désignation}`);
                setDataDetail(response.data);

                // console.log(data);
                setPopupOpen(true); // Ouvrez la popup
            })
            .catch(error => {
                console.log(error);
            });

    };


    return (

        <MuiThemeProvider theme={theme}>
            <div className='row'>
                <div className="col-7">
                    <MaterialTable
                        title="Ventes"
                        columns={columns.map(col => ({ ...col, cellStyle }))}
                        data={data}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 20],
                            sorting: true,
                            exportButton: true,
                            exportAllData: true,
                            padding: 'dense',
                        }}
                        style={{ flex: '1' }}
                        onRowClick={(e, row) => handleDetailClick(row, 'idp')}
                    />
                </div>
                <div className="col-5">
                    <div className="row">
                        <div className="col-6">
                            <div className="d-flex flex-column align-items-center">
                                <Doughnut
                                    data={dataTypeProduit}
                                    legend={{ display: false }}
                                    width={50}
                                    height={50}
                                    options={{
                                        tooltips: {
                                            enabled: false // Désactiver les infobulles
                                        },

                                    }}
                                />

                                <div className="mt-3">
                                    {/* Légendes du premier donut */}
                                    {dataTypeProduit.labels.map((label, index) => (
                                        <div key={index} className="d-flex align-items-center">
                                            <div
                                                style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    backgroundColor: dataTypeProduit.datasets[0].backgroundColor[index],
                                                    marginRight: '5px',
                                                }}
                                            ></div>
                                            <span>{label}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex flex-column align-items-center">
                                <Doughnut data={dataCouleurOr} legend={{ display: false }} width={50} height={50} options={{
                                    tooltips: {
                                        enabled: false // Désactiver les infobulles
                                    },

                                }} />
                                <div className="mt-3">
                                    {/* Légendes du deuxième donut */}
                                    {dataCouleurOr.labels.map((label, index) => (
                                        <div key={index} className="d-flex align-items-center">
                                            <div
                                                style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    backgroundColor: dataCouleurOr.datasets[0].backgroundColor[index],
                                                    marginRight: '5px',
                                                }}
                                            ></div>
                                            <span>{label}</span>
                                        </div>
                                    ))}
                                </div>
                                <PopupTable
                                    open={popupOpen}
                                    handleClose={() => setPopupOpen(false)}
                                    columns={columnsData}
                                    title={title}
                                    data={dataDetail}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MuiThemeProvider>
    );

};

export default DataGridVente;
