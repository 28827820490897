import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { getListForSqlIn } from '../helpers/functions';

const FilterInput = ({ label = 'Label', onChange, type = 'number' }) => {
    const [current, setCurrent] = useState([]);
    const [selected, setSeleced] = useState([]);

    const handleChange = (e) => {
        setCurrent(e.target.value);
    };

    const handleRemoveItem = id => {
        setSeleced(selected.filter(item => item !== id));
        onChange(getListForSqlIn(selected.filter(item => item !== id), ''), label);
    };

    const onEnter = e => {
        if (e.key === 'Enter' && current !== '') {
            const exist = selected.find(r => r === current);
            if (exist === undefined) {
                setSeleced(selected.concat(current));
                onChange(getListForSqlIn(selected.concat(current)), label);
                setCurrent('');
            }
        }
    };

    const reset = () => {
        setSeleced([]);
        onChange('', label);
        setCurrent('');
    };

    return (
        <div className='form-group' style={{ textAlign: 'center', maxWidth: 100, minHeight: 250, maxHeight: 250 }}>
            <TextField
                fullWidth
                type={type}
                onKeyPress={onEnter}
                onChange={handleChange}
                value={current}
                label={label}
                variant="outlined"
                margin="dense"
            />
            <div>
                {selected.length !== 0 && <button className="btn btn-link btn-sm" onClick={reset}>Clear</button>}
            </div>
            <div className='overflow-auto' style={{ maxHeight: 150, overflowY: 'auto' }}>
                {selected.map((item) => {
                    return (
                        <div style={{ textAlign: 'center' }} onClick={() => handleRemoveItem(item)} key={item}>
                            {item}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FilterInput;
