import { TextField } from '@material-ui/core';
import Axios from 'axios';
import { List } from 'immutable';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getListeRetourPierre } from '../../actions'
import { localization, meekroDB } from '../../config';
const RetourPierre = ({ id, listePierre, getListeRetourPierre }) => {
    const [idRetour, setIdRetour] = useState('')
    const [idConfie, setIdConfie] = useState(id || '')
    const [poids, setPoids] = useState('')
    const [motif, setMotif] = useState('')
    const [btn, setBtn] = useState('save')

    // const [columns, setColumns] = useState([
    //     { title: 'ID Retour', field: 'id_retour', type: 'numeric', align: 'left' },
    //     { title: 'ID Confié', field: 'id_confie', type: 'numeric', align: 'left' },
    //     { title: 'Poids', field: 'pds', type: 'numeric', align: 'left' },
    //     { title: 'Date', field: 'date', align: 'left' },
    // ])



    useEffect(() => getListeRetourPierre(), [getListeRetourPierre]);
    const selectRetour = (values) => {
        // console.log(values);
        if (values) {
            setIdRetour(values.id_retour)
            setIdConfie(values.id_confie)
            setPoids(values.pds)
            setMotif(values.designation)
        }
    }
    const handleAdd = () => {
        const data = {
            table: 'pierre_retour',
            id_confie: idConfie,
            pds: poids,
            designation: motif,
        }
        Axios.post(`${meekroDB}/general.php`, data)
            .then((response) => {
                getListeRetourPierre()
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleSave = () => {
        const data = {
            table: 'pierre_retour',
            primaryKey: 'id_retour',
            id: idRetour,
            id_confie: idConfie,
            pds: poids,
            designation: motif,
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                getListeRetourPierre()
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleRemove = (id) => {
        if (id && id !== '') {
            const data = {
                table: 'pierre_retour',
                primaryKey: 'id_retour',
                id: idRetour,
            }
            Axios.post(`${meekroDB}/general_remove.php`, data)
                .then((response) => {
                    getListeRetourPierre()
                })
                .catch(error => {
                    console.log(error);
                });

        }
    }
    const handleSubmit = e => {
        e.preventDefault()
        console.log(btn);
        if (btn === 'add') handleAdd(e)
        else if (btn === 'save') handleSave(e)
        else if (btn === 'remove') handleRemove(e)
    }
    // const handleOnOrderChange = (orderedColumnId, orderDirection) => {
    //     setColumns(columns.map((current, i) => {
    //         if (i === orderedColumnId) current.defaultSort = orderDirection
    //         else current.defaultSort = null
    //         return current
    //     }))
    //     // if (orderedColumnId !== -1) {
    //     //     for (let i = 0; i < this.columns.length; i++) {
    //     //         if (i === orderedColumnId) this.columns[i].defaultSort = orderDirection
    //     //         else this.columns[i].defaultSort = null
    //     //     }
    //     // }
    //     // else this.columns.forEach(c => c.defaultSort = null)

    // }
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-sm">
                    <MaterialTable
                        style={{ fontSize: 12 }}

                        columns={[
                            { title: 'ID Retour', field: 'id_retour', type: 'numeric', align: 'left' },
                            { title: 'ID Confié', field: 'id_confie', type: 'numeric', align: 'left' },
                            { title: 'Poids', field: 'pds', type: 'numeric', align: 'left' },
                            { title: 'Date', field: 'date', align: 'left' },
                        ]}
                        // onOrderChange={handleOnOrderChange}
                        options={{
                            rowStyle: rowData => ({
                                backgroundColor: (idRetour === rowData.id_retour) ? '#AED1FF' : ''
                            })
                        }}
                        onRowClick={(e, values) => selectRetour(values)}
                        data={List(listePierre).toJS()}
                        //.filter(r => r.id_confie.toLowerCase().indexOf(idConfie.toLowerCase()) > -1 || idConfie === '')
                        title={"Historique Retour Id : " + idConfie}

                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                </div>
                            ),
                        }}

                        localization={localization}
                    />
                </div>
                <div className="col-sm">
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            <TextField
                                type='number' label='Id Confié'
                                onChange={e => setIdConfie(e.target.value)}
                                value={idConfie}
                                required
                                variant='outlined'

                            />
                        </div>
                        <div className="form-group">

                            <TextField
                                type='number' label='Poids'
                                onChange={e => setPoids(e.target.value)}
                                value={poids}
                                required
                                variant='outlined'

                            />
                        </div>
                        <div className="form-group">
                            <TextField
                                label='Motif'
                                onChange={e => setMotif(e.target.value)}
                                value={motif}
                                variant='outlined'

                            />
                        </div>



                        <div className="form-group">
                            <button type="submit" className="btn btn-link btn-sm" onClick={() => setBtn('add')}>Ajouter</button>
                            {idConfie !== '' && <>
                                <button type="submit" className="btn btn-link btn-sm" onClick={() => setBtn('save')}>Modifier</button>
                                <button type="submit" className="btn btn-link btn-sm" onClick={() => setBtn('remove')}>Supprimer</button>
                            </>}
                        </div>
                    </form>
                </div>

            </div>

        </div >
    )
}
const mapStateToProps = (state) => {
    return {
        listePierre: state.pierre.listeRetourPierre
    }
}
const mapDispatchToProps = {
    getListeRetourPierre,
}

export default connect(mapStateToProps, mapDispatchToProps)(RetourPierre)