import Axios from 'axios';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { getListeProduit } from '../actions'
import { meekroDB } from '../config';

const SuppressionCvPierre = ({ listeProduit, getListeProduit }) => {
    useEffect(() => getListeProduit(), [getListeProduit]);

    const prepareToPush = (data, centre, toDelete = false) => {
        if (toDelete) return ({ id_centre: centre, id_pierre: data.id_pierre })
        else return ({ id_centre: centre, id_pierre: data.id_pierre, qte: data.qte, pds_r: data.pds_r, centre: 0 })
    }
    const deleteCentrePierre = (toDelete) => {
        const data = {
            toDelete: toDelete
        }
        // console.log(data);
        Axios.put(`${meekroDB}/pierre/pierre_remove.php`, data)
            .then((response) => {
                //console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

    }
    let tab = []
    let toDelete = []
    const supprimerCvPierre = () => {
        Axios.get(`${meekroDB}/general.php?table=cv_pierre`)
            .then(response => {
                Object.values(response.data).forEach(cv => {
                    Object.values(listeProduit).filter(f => f.id_cv === cv.id_cv).forEach(c => {
                        tab.push(prepareToPush(cv, c.id_centre))
                        toDelete.push(prepareToPush(cv, c.id_centre, true))
                    })
                })
                deleteCentrePierre(toDelete)
                const data = {
                    records: tab,
                    table: 'centre_pierres',
                }
                // console.log(data);

                Axios.post(`${meekroDB}/general_multi.php`, data)
                    .then(response => {
                        //console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    });

            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <div>
            <button onClick={supprimerCvPierre}>Suppression Centre Pierre</button>
            <button onClick={() => console.log(tab)}>Suppression CV Pierre</button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        listeProduit: state.produit.listeProduit,
    }
}
const mapDispatchToProps = {
    getListeProduit,

}
export default connect(mapStateToProps, mapDispatchToProps)(SuppressionCvPierre)