import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DetailOf from './DetailOf';
import FiltrePlanning from './FiltrePlanning';
import { apiATC } from '../../config';
import Axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    gridContainer: {
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    paper: {
        padding: '0',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minHeight: '100px',
        borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        marginBottom: '20px',
    },
    dayNumber: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    draggableItem: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#90caf9', // Bleu clair pour le hover
        },
    },
    printButton: {
        marginTop: '10px',
    },
}));

const PlanningHebdomadaire = () => {
    const classes = useStyles();
    const [semaine, setSemaine] = useState([]);
    const [listeOfs, setListeOfs] = useState([]);

    // Mise à jour de la fonction joursDeLaSemaine
    const joursDeLaSemaine = (date) => {
        const joursSemaine = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
        const joursAvecDates = [];

        let now = new Date();
        let currentDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());


        while (joursAvecDates.length < 6) {
            const dayOfWeek = currentDate.getDay();

            // Ajouter uniquement les jours ouvrés (lundi à vendredi)
            if (dayOfWeek >= 1 && dayOfWeek <= 5) {
                joursAvecDates.push({
                    jour: joursSemaine[(dayOfWeek - 1) % 5], // Associer le jour (Lundi, Mardi, etc.)
                    date: new Date(currentDate), // Ajouter la date correspondante
                });
            }

            // Passer au jour suivant
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return joursAvecDates;
    };

    // Gestion du filtre et du chargement initial
    const handleFiltre = (data) => {
        setSemaine(joursDeLaSemaine(data.dateF)); // Générer les jours basés sur la date
        setListeOfs(data.dataOfs); // Charger la liste des OFs
    };

    // Fonction pour imprimer une semaine complète
    const imprimerSemaine = () => {
        const today = new Date();
        const endDate = new Date();
        endDate.setDate(today.getDate() + 5); // Ajouter 5 jours ouvrables

        const displayedOfs = listeOfs.filter((of) => {
            const ofDate = new Date(of.date_livr);
            return ofDate >= today && ofDate <= endDate;
        });

        const ofsString = displayedOfs.map((of) => of.num_sachet).join(',');
        const url = `https://www.extensia-france.com/printers/planning.php?ofs=${ofsString}`;
        window.open(url, '_blank');
    };

    // Confirmation du déplacement d’un OF
    const confirmDrag = async (movedItem) => {
        const data = {
            table: 'of',
            primaryKey: 'num_sachet',
            id: movedItem.num_sachet,
            date_livr: movedItem.date_livr,
        };

        try {
            const response = await Axios.post(`${apiATC}/general.php?action=modifier`, data);
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            console.error('Erreur lors de la confirmation du déplacement :', error);
        }
        return false;
    };

    // Gestion du drag-and-drop
    const onDragEnd = async (result) => {
        if (!result.destination) return;

        const updatedListeOfs = [...listeOfs];
        const movedItem = updatedListeOfs.find((of) => of.num_sachet === result.draggableId);

        if (movedItem) {
            movedItem.date_livr = semaine[result.destination.droppableId].date.toISOString().split('T')[0];

            // Supprimer l'élément de sa position actuelle
            updatedListeOfs.splice(updatedListeOfs.indexOf(movedItem), 1);

            // Insérer l'élément à sa nouvelle position
            updatedListeOfs.splice(result.destination.index, 0, movedItem);
        }

        const isConfirmed = await confirmDrag(movedItem);
        if (isConfirmed) {
            setListeOfs(updatedListeOfs); // Mettre à jour l’état si le déplacement est confirmé
        } else {
            console.log('Le déplacement n\'a pas été confirmé.');
        }
    };

    // Fonction pour imprimer les OFs d’un jour donné
    const handlePrintDay = (ofs) => {
        const ofsString = ofs.map((of) => of.num_sachet).join(',');
        const url = `https://www.extensia-france.com/printers/planning.php?ofs=${ofsString}`;
        window.open(url, '_blank');
    };

    return (
        <div className={classes.root}>
            <FiltrePlanning setListeOfs={handleFiltre} print={imprimerSemaine} />
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container spacing={1} className={classes.gridContainer} id="planning">
                    {semaine.map((day, index) => (
                        <Grid item style={{ width: '16%' }} key={day.jour + index}>
                            <Paper className={classes.paper} style={{ minHeight: 'auto', padding: '5px' }}>
                                <Typography variant="h6">{day.jour.toUpperCase()}</Typography>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.dayNumber}
                                >
                                    {day.date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit' })}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.printButton}
                                    onClick={() =>
                                        handlePrintDay(
                                            listeOfs.filter(
                                                (of) =>
                                                    new Date(of.date_livr).toDateString() ===
                                                    day.date.toDateString()
                                            )
                                        )
                                    }
                                >
                                    Imprimer
                                </Button>
                            </Paper>
                            <Paper className={classes.paper}>
                                <Droppable droppableId={`${index}`}>
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{ padding: '0', flex: '1' }}
                                        >
                                            {listeOfs
                                                .filter(
                                                    (of) =>
                                                        new Date(of.date_livr).toDateString() ===
                                                        day.date.toDateString()
                                                )
                                                .map((of, index) => (
                                                    <Draggable
                                                        key={of.num_sachet}
                                                        draggableId={of.num_sachet}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={classes.draggableItem}
                                                            >
                                                                <DetailOf data={[of]} />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </DragDropContext>
        </div>
    );
};

export default PlanningHebdomadaire;

