import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import MaterialTable, { MTableToolbar } from 'material-table'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { inArray } from '../../helpers/functions'
import { getListeTypePierre, getListeFormePierre, getListeCouleurPierre, getListeQualitePierre, getListePierre, } from '../../actions'
import FilterSelect from '../../components/filterSelect'
import { localization } from '../../config'
import { getPierre } from '../../selectors/pierre'
import PierreEdit from '../../components/pierre/PierreEdit'
import PierreChangePrice from '../../components/pierre/PierreChangePrice'


class ListePierres extends Component {
    _isMounted = false;
    state = {
        type: '',
        forme: '',
        couleur: '',
        qualite: '',
        poidsMin: '',
        poidsMax: '',
        editer: false,
        tarif: false,
        filteredData: [],
        selectedRow: [],
    }
    componentDidMount() {
        this._isMounted = true
        this.props.getListeTypePierre()
        this.props.getListeFormePierre()
        this.props.getListeCouleurPierre()
        this.props.getListeQualitePierre()
        this.rafraichir()
    }
    rafraichir = () => {
        this.props.getListePierre().then(() => this.filterData())
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    filterData = () => {
        if (this._isMounted) {
            const data = [...this.props.listePierre]
            // console.log(this.state.type.split(','));
            const filteredData = Object.values(data)
                .filter(t => inArray(t.type_pier, this.state.type.split(',')) || this.state.type === '')
                .filter(f => inArray(f.forme, this.state.forme.split(',')) || this.state.forme === '')
                .filter(c => inArray(c.color, this.state.couleur.split(',')) || this.state.couleur === '')
                .filter(q => inArray(q.id_qual, this.state.qualite.split(',')) || this.state.qualite === '')

                .filter(min => min.lePoids >= this.state.poidsMin || this.state.poidsMin === '')
                .filter(max => max.lePoids <= this.state.poidsMax || this.state.poidsMax === '')

            this.setState({ filteredData })
        }
    }
    onChange = (data, labelState) => {
        const label = labelState.toLowerCase()
        this.setState({ [label]: data }, () => this.filterData())
    }
    editPierre = (selectedRow) => {
        this.setState({ selectedRow, editer: true })
    }
    changePrice = (selectedRow) => {

        this.setState({ selectedRow, tarif: true })
    }

    render() {
        return (
            <div>
                <div className='row' style={{ minHeight: 200 }}>
                    <div className="col-sm">
                        <FilterSelect label='Type' labelId='id_typ_pier' onChange={this.onChange} options={this.props.typePierre} />
                    </div>
                    <div className="col-sm">
                        <FilterSelect label='Forme' labelId='id_form' onChange={this.onChange} options={this.props.formePierre} />
                    </div>
                    <div className="col-sm">
                        <FilterSelect label='Couleur' labelId='id_color' onChange={this.onChange} options={this.props.listeCouleurPierre} />
                    </div>
                    <div className="col-sm">
                        <FilterSelect label='Qualite' labelId='id_qual' onChange={this.onChange} options={this.props.listeQualitePierre} />
                    </div>
                    <div className='form-group' >
                        <TextField
                            type='number' label='Poids Min'
                            onChange={e => this.setState({ poidsMin: e.target.value })}
                            value={this.state.poidsMin}
                            variant='outlined'
                            margin='dense'
                        />
                    </div>

                    <div className="col-sm">
                        <div className='form-group' >
                            <TextField
                                type='number' label='Poids Max'
                                onChange={e => this.setState({ poidsMax: e.target.value })}
                                value={this.state.poidsMax}
                                variant='outlined'
                                margin='dense'
                            />
                        </div>
                    </div>

                    <div className="col-auto">
                        <button style={{ minHeight: 150 }} className='btn btn-primary btn-lg' onClick={e => this.rafraichir()}>Rafraichir</button>
                    </div>


                </div>
                <div>
                    <MaterialTable
                        style={{ fontSize: 12 }}

                        columns={[
                            { title: 'Type', field: 'type', align: 'left' },
                            { title: 'Forme', field: 'laforme', align: 'left' },
                            { title: 'Couleur', field: 'couleur', align: 'left' },
                            { title: 'Poids', field: 'lePoids', align: 'left' },
                            { title: 'Description', field: 'descriptionPierre', align: 'left', width: 700 },
                            { title: 'Qualité', field: 'laqual', align: 'left' },
                            { title: 'Euro', field: 'euro', align: 'left' },
                            { title: 'Dollar', field: 'dollar', align: 'left' },
                            { title: 'Coef', field: 'coeff', align: 'left' },
                        ]}
                        options={{
                            pageSize: 10,
                            selection: true,
                            actionsColumnIndex: -1,
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Ajouter',
                                onClick: () => this.editPierre([]),
                                position: 'toolbar'
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Editer',
                                onClick: (e, rowData) => this.editPierre(rowData),
                                position: 'row'
                            }

                            , rowData => ({
                                icon: 'euro_symbol',
                                tooltip: 'Modifier Prix',
                                onClick: () => this.changePrice(rowData),
                                position: 'toolbarOnSelect'

                            }),

                            // {
                            //     tooltip: 'Supprimer',
                            //     icon: 'delete',
                            //     onClick: (evt, data) => this.supprimer(data)
                            // }
                        ]}
                        data={this.state.filteredData}
                        title="Liste Pierres"

                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                </div>
                            ),
                        }}

                        onRowClick={this.onRowClick}
                        localization={localization}
                    />
                </div>

                <Dialog
                    open={this.state.editer}
                    onClose={() => this.setState({ editer: false })}
                    fullWidth={true}
                    maxWidth={'md'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Modification Pierre</DialogTitle>
                    <DialogContent >
                        <div className='d-flex justify-content-center'>
                            <PierreEdit data={this.state.selectedRow} onAction={this.rafraichir} />
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ editer: false })} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.tarif}
                    onClose={() => this.setState({ tarif: false })}
                    fullWidth={true}
                    maxWidth={'md'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Modification Tarif Pierre</DialogTitle>
                    <DialogContent >
                        <div className='d-flex justify-content-center'>
                            <PierreChangePrice data={this.state.selectedRow} onAction={this.rafraichir} />
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ tarif: false })} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        typePierre: state.pierre.listeTypePierre,
        formePierre: state.pierre.listeFormePierre,
        listeQualitePierre: state.pierre.listeQualitePierre,
        listeCouleurPierre: state.pierre.listeCouleurPierre,
        listePierre: getPierre(state),
    }
}
const mapDispatchToProps = {
    getListeTypePierre,
    getListeFormePierre,
    getListeCouleurPierre,
    getListeQualitePierre,
    getListePierre,
}
export default connect(mapStateToProps, mapDispatchToProps)(ListePierres)